import { CSSProperties, SVGProps, useMemo } from "react";
import { useAppSelector } from "../../redux/hooks";

const boxWidth = 15;
const boxHeight = 3;

function convertRemToPixels(rem: number) {
  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}

export function RotatingCircle({
  circleId,
  onClick,
  startAngle,
  endAngle,
  rectFont,
  ...props
}: SVGProps<SVGSVGElement> & {
  circleId: string;
  startAngle: number;
  endAngle: number;
  rectFont?: CSSProperties["fontSize"];
  onClick?: (value: any) => void;
}) {
  const cx = 370;
  const cy = 370;
  const radius = 370;
  const diffAngle = endAngle - startAngle;

  const circle = useAppSelector((state) =>
    state.elite.circles.find((circle) => circle.id === circleId)
  );

  const optionLength = useMemo(() => circle?.options.length, [circle?.options]);

  const arcSize = useMemo(
    () =>
      circle?.options !== undefined ? diffAngle / circle.options.length : 0,
    [circle?.options, diffAngle]
  );

  return (
    <div>
      <svg viewBox="0 0 740 740" {...props}>
        <g>
          <radialGradient
            id="_1-2_00000045613219711968469900000012457023523339681182_"
            cx={cx}
            cy={cy}
            r={radius}
            gradientUnits="userSpaceOnUse"
          >
            <stop
              offset={0}
              style={{
                stopColor: "#fff",
                stopOpacity: 0,
              }}
            />
            <stop
              offset={0.302}
              style={{
                stopColor: "#fdfdfd",
                stopOpacity: 0.09047958,
              }}
            />
            <stop
              offset={0.439}
              style={{
                stopColor: "#f6f6f6",
                stopOpacity: 0.1317,
              }}
            />
            <stop
              offset={0.543}
              style={{
                stopColor: "#e9e9e9",
                stopOpacity: 0.163,
              }}
            />
            <stop
              offset={0.631}
              style={{
                stopColor: "#d7d7d7",
                stopOpacity: 0.1892,
              }}
            />
            <stop
              offset={0.708}
              style={{
                stopColor: "#bfbfbf",
                stopOpacity: 0.2123,
              }}
            />
            <stop
              offset={0.777}
              style={{
                stopColor: "#a2a2a2",
                stopOpacity: 0.2332,
              }}
            />
            <stop
              offset={0.841}
              style={{
                stopColor: "#7f7f7f",
                stopOpacity: 0.2524,
              }}
            />
            <stop
              offset={0.901}
              style={{
                stopColor: "#575757",
                stopOpacity: 0.2703,
              }}
            />
            <stop
              offset={0.955}
              style={{
                stopColor: "#2b2b2b",
                stopOpacity: 0.2865,
              }}
            />
            <stop
              offset={1}
              style={{
                stopColor: "#000",
                stopOpacity: 0.3,
              }}
            />
          </radialGradient>
          <circle
            cx={cx}
            cy={cy}
            r={radius - 2}
            style={{
              fill: "url(#_1-2_00000045613219711968469900000012457023523339681182_)",
            }}
          />
        </g>
        {optionLength !== undefined &&
          (circle?.options.map((option, index) => {
            const offset =
              ((index + circle.position) / (optionLength + 1)) * diffAngle;
            const diff = circle.selected - (index + 1);
            const angleOffset = (1 - diff) * 0.5;
            const normalizedOffset = offset + offset * (1 / optionLength);
            const span = Math.floor(90 / arcSize);
            if (Math.abs(diff) > span) return <></>;
            const arc = describeArc({
              cx,
              cy,
              radius,
              startAngle: startAngle + normalizedOffset,
              endAngle: startAngle + normalizedOffset + arcSize,
            });
            const isSelected = Math.abs(diff) < 1;
            const { x, y } = polarToCartesian({
              centerX: cx - convertRemToPixels(boxWidth) / 2,
              centerY: cy - convertRemToPixels(boxHeight) / 2,
              radius: radius + 50,
              angleInDegrees:
                startAngle + (index + angleOffset + circle.position) * arcSize,
            });
            return (
              <g key={index} onClick={() => onClick?.(option.value)}>
                {isSelected && (
                  <path
                    d={arc}
                    fill="none"
                    stroke="#000"
                    opacity={1 - Math.abs(diff)}
                    strokeWidth="4"
                    strokeLinecap="round"
                  />
                )}
                <rect
                  x={x}
                  y={y}
                  width={`${boxWidth}rem`}
                  height={`${boxHeight}rem`}
                  style={{
                    fill: "white",
                    stroke: "black",
                    strokeWidth: 1,
                    opacity: 1.5 - Math.abs(diff),
                  }}
                />
                <text
                  x={x + convertRemToPixels(boxWidth) / 2}
                  y={y + convertRemToPixels(boxHeight) / 2}
                  dominantBaseline="middle"
                  textAnchor="middle"
                  fill="black"
                  color="black"
                  fontSize={rectFont}
                  fontFamily="ProximaNova"
                  fontWeight={400}
                  opacity={1.5 - Math.abs(diff)}
                  fontStyle={"italic"}
                >
                  {option.text}
                </text>
              </g>
            );
          }) ?? <></>)}
      </svg>
    </div>
  );
}

function polarToCartesian({
  centerX,
  centerY,
  angleInDegrees,
  radius,
}: {
  centerX: number;
  centerY: number;
  radius: number;
  angleInDegrees: number;
}) {
  var angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  };
}

function describeArc({
  cx,
  cy,
  radius,
  startAngle,
  endAngle,
}: {
  cx: number;
  cy: number;
  radius: number;
  startAngle: number;
  endAngle: number;
}) {
  var start = polarToCartesian({
    centerX: cx,
    centerY: cy,
    radius,
    angleInDegrees: endAngle,
  });
  var end = polarToCartesian({
    centerX: cx,
    centerY: cy,
    radius,
    angleInDegrees: startAngle,
  });

  var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

  var d = [
    "M",
    start.x,
    start.y,
    "A",
    radius,
    radius,
    0,
    largeArcFlag,
    0,
    end.x,
    end.y,
  ].join(" ");

  return d;
}
