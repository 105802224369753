import { ArrowDownIcon, RightIcon } from "../Icons/ArrowUP";
import { Colors } from "../../styles/theme";
import { Grid, GridProps, Typography } from "@mui/material";

interface ShowMoreProps {
  onClick?: GridProps["onClick"];
  color: Colors;
}
export function ShowMore(props: ShowMoreProps) {
  return (
    <Grid
      container
      sx={{ backgroundColor: `${props.color}.main`, color: "white" }}
      direction={"row"}
      justifyContent={"space-between"}
      alignContent={"center"}
      py={1}
      px={2}
      width={"15rem"}
      onClick={props.onClick}
    >
      <Grid item xs={8}>
        <Grid
          container
          direction={"column"}
          justifyContent={"center"}
          height={"100%"}
        >
          <Typography variant="h6">Scopri di più</Typography>
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <Grid
          container
          width={"100%"}
          direction={"row"}
          justifyContent={"center"}
          alignContent={"center"}
        >
          <ArrowDownIcon height={"2rem"} />
        </Grid>
      </Grid>
    </Grid>
  );
}

interface IniziaProps {
  onClick?: GridProps["onClick"];
  active?: boolean;
}

export function Inizia(props: IniziaProps) {
  return (
    <Grid
      container
      sx={{
        backgroundColor: props.active ? "debianRed.main" : "#d8d8d8",
        color: props.active ? "white" : "black",
      }}
      direction={"row"}
      justifyContent={"space-between"}
      alignContent={"center"}
      py={1}
      px={2}
      width={"10rem"}
      onClick={props.onClick}
    >
      <Grid item xs={8}>
        <Grid
          container
          direction={"column"}
          justifyContent={"center"}
          height={"100%"}
        >
          <Typography variant="body1">INIZIA</Typography>
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <Grid
          container
          width={"100%"}
          direction={"row"}
          justifyContent={"center"}
          alignContent={"center"}
        >
          <RightIcon
            height={"1.5rem"}
            fill={props.active ? "white" : "black"}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
