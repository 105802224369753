import React, { createContext, useContext, useEffect, useState } from "react";
import PouchDB from "pouchdb-browser";

const db = new PouchDB("assets");
interface AssetsLoaderProps {
  assetsUrl: Map<string, string>;
  children?: React.ReactNode;
  loading?: (props: {
    percentage: number;
    size: number;
    shouldContinue: (e: boolean) => void;
  }) => JSX.Element;
  type?: "local" | "blob";
}

type Asset = string;
export type IAssetContext = Record<string, Asset>;

async function loadUrl(
  key: string,
  url: string
): Promise<{ url: string; size: number } | undefined> {
  return { url, size: 0 };
}

async function loadBlob(
  key: string,
  url: string | undefined
): Promise<{ url: string; size: number } | undefined> {
  if (url === undefined) {
    throw new Error("Url is undefined");
  }
  const urlPathname = new URL(url).pathname;
  let result: { url: string; size: number } | undefined;
  if (url.startsWith("data:image/png;base64")) {
    result = { url, size: new TextEncoder().encode(url).length };
    // db.putAttachment(imageDOC, key, url, "image/png");
  } else if (
    urlPathname.endsWith(".jpg") ||
    urlPathname.endsWith(".jpeg") ||
    urlPathname.endsWith(".png") ||
    urlPathname.endsWith(".gif")
  ) {
    //GET EXTENSION OF url, that is all string after last dot
    const blob = await downloadBlob({
      url,
      key,
    });
    const src = URL.createObjectURL(blob);
    const image = new Image();
    image.src = src;
    if (blob !== undefined && blob instanceof Blob) {
      result = { url: src, size: blob.size };
    }
  } else if (urlPathname.endsWith(".mp3") || urlPathname.endsWith(".wav")) {
    throw new Error("Audio not supported");
    // const audio = new Audio();
    // audio.src = url;
    // audio.oncanplaythrough = () => resolve(audio);
  } else if (
    urlPathname.endsWith(".mp4") ||
    urlPathname.endsWith(".webm") ||
    urlPathname.endsWith(".mov")
  ) {
    //GET EXTENSION OF url, that is all string after last dot
    const blob = await downloadBlob({
      url,
      key,
    });
    if (blob !== undefined && blob instanceof Blob) {
      result = { url: URL.createObjectURL(blob), size: blob.size };
    }
  }
  if (result !== undefined) {
    return result;
  } else {
    console.warn("Unknown asset type " + url);
  }
}

async function downloadBlob({
  url,
  key,
}: {
  url: string;
  key: string;
}): Promise<Blob> {
  try {
    const res = await db.getAttachment(key, url.replace("/static/media/", ""));
    if (res instanceof Blob) return res;
  } catch {}
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const blob = await response.blob();
    try {
      const document = await db.get(key).catch(() => null);
      if (document == null) {
        await db.putAttachment(
          key,
          url.replace("/static/media/", ""),
          blob,
          blob.type
        );
      } else {
        await db.putAttachment(
          key,
          url.replace("/static/media/", ""),
          document._rev,
          blob,
          blob.type
        );
      }
    } catch (error) {
      console.warn(`Error saving ${key} to local db`, error);
    }
    return blob;
  } catch (error) {
    throw error;
  }
}

export const AssetContext = createContext<IAssetContext>({});

var isLoading = false;

export function AssetsLoader({
  assetsUrl,
  children,
  loading,
  type,
}: AssetsLoaderProps) {
  const [assets, setAssets] = useState<IAssetContext | null>(null);
  const [percentage, setPercentage] = useState(0);
  const [size, setSize] = useState(0);
  const [shouldContinue, setShouldContinue] = useState(loading === undefined);

  useEffect(() => {
    async function loadAssets() {
      if (isLoading) return;
      isLoading = true;
      let newAssets: IAssetContext = {};
      const entries = Array.from(assetsUrl.entries());
      let index = 1;
      const total = entries.length;
      for (const [key, value] of entries) {
        try {
          const res =
            type === "local"
              ? await loadUrl(key, value)
              : await loadBlob(key, value);
          if (res !== undefined) {
            newAssets[key] = res.url;
            setSize((e) => e + res.size);
          }
        } catch (error) {
          console.error(error);
        }
        setPercentage(index / total);
        index += 1;
      }
      setAssets(newAssets);
    }

    loadAssets();
  }, [assetsUrl, type]);

  return assets != null && shouldContinue ? (
    <AssetContext.Provider value={assets} children={children} />
  ) : (
    loading?.call(undefined, {
      percentage,
      size,
      shouldContinue: (res) => setShouldContinue(res),
    }) ?? <></>
  );
}

export function useAsset<T extends string = string>(key: T | ReadonlyArray<T>) {
  const context = useContext(AssetContext);
  if (!context) {
    throw new Error("useImage must be used within an AssetsLoader");
  }
  return (Array.isArray(key) ? key : [key]).map((e) => context[e]);
}

export function useSingleAsset<T extends string = string>(key: T | undefined) {
  const context = useContext(AssetContext);
  if (!context) {
    throw new Error("useImage must be used within an AssetsLoader");
  }
  if (key === undefined) {
    return undefined;
  }
  return context[key];
}
