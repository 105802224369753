import { Grid, Box, GridProps } from "@mui/material";
import { forwardRef, useMemo, useState } from "react";
import { LeftIcon, RightIcon } from "../Icons/ArrowUP";
import { VerticalGrid } from "../HorizontalGrid/HorizontalGrid";
import { Center } from "../../pages/elite/digital_marketing";
import { Dot } from "../Card/VerticalScrollableCard";

export function useCarouselHandler(length: number, initial = 0) {
  const [activeIndex, setActiveIndex] = useState(initial);
  const prev = useMemo(
    () =>
      activeIndex === 0 ? undefined : () => setActiveIndex((prev) => prev - 1),
    [activeIndex]
  );

  const next = useMemo(
    () =>
      activeIndex >= length - 1
        ? undefined
        : () => setActiveIndex((prev) => prev + 1),
    [activeIndex, length]
  );

  return { activeIndex, prev, next, setActiveIndex };
}

interface CarouselProps {
  prev?: () => void;
  next?: () => void;
  setActiveIndex: (index: number) => void;
  children?: GridProps["children"];
  items: number;
  activeIndex: number;
}

export const Carousel = forwardRef(function Carousel(
  props: CarouselProps,
  ref: React.Ref<HTMLDivElement>
) {
  return (
    <VerticalGrid
      type="container"
      rows={["10rem", "auto", "10rem"]}
      sx={{ width: "100%", height: "100%" }}
    >
      <VerticalGrid type="item" onClick={console.log} />
      <VerticalGrid type="item" children={props.children} ref={ref} />
      <VerticalGrid type="item" onClick={console.log}>
        <Center>
          <Grid
            container
            direction={"row"}
            justifyContent={"center"}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <Box
              minWidth={80}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                props.prev?.call(undefined);
              }}
              visibility={props.prev !== undefined ? "visible" : "hidden"}
            >
              <Grid
                direction="column"
                container
                alignContent="center"
                justifyContent="center"
                height={"100%"}
              >
                <LeftIcon width={"1rem"} />
              </Grid>
            </Box>
            <Grid item>
              <Grid
                container
                direction={"row"}
                justifyContent={"center"}
                alignContent={"center"}
                gap={1}
                height={"100%"}
              >
                {props.items > 1 &&
                  new Array(props.items)
                    .fill(0)
                    .map((_, i) => (
                      <Dot
                        key={i}
                        color="white"
                        size={"1rem"}
                        active={i === props.activeIndex}
                        onClick={() => props.setActiveIndex(i)}
                      />
                    ))}
              </Grid>
            </Grid>
            <Box
              minWidth={80}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                props.next?.call(undefined);
              }}
              visibility={props.next !== undefined ? "visible" : "hidden"}
            >
              <Grid
                direction="column"
                container
                alignContent="center"
                justifyContent="center"
                height={"100%"}
              >
                <RightIcon width={"1rem"} />
              </Grid>
            </Box>
          </Grid>
        </Center>
      </VerticalGrid>
    </VerticalGrid>
  );
});

//  return (
//    <Grid
//      container
//      alignItems="center"
//      justifyContent={"center"}
//      width={"100%"}
//      height="100%"
//      direction="row"
//      color={"white"}
//    >
//      <Grid item onClick={preventDefault}>
//        <Box
//          minWidth={80}
//          onClick={props.prev}
//          visibility={props.prev !== undefined ? "visible" : "hidden"}
//        >
//          <Grid
//            direction="column"
//            container
//            alignContent="center"
//            justifyContent="center"
//            height={"100%"}
//          >
//            <LeftIcon width={"1rem"} />
//          </Grid>
//        </Box>
//      </Grid>
//      <Grid item onClick={preventDefault}>
//        <Grid
//          container
//          height={"100%"}
//          direction={"column"}
//          justifyContent={"center"}
//          alignItems={"center"}
//        >
//          <Grid item>
//            <Box>
//              <Grid
//                container
//                height={"100%"}
//                direction="row"
//                justifyContent="center"
//                alignContent="center"
//                gap={1}
//              >
//                <Grid item height={"100%"} children={props.children} />
//              </Grid>
//            </Box>
//          </Grid>
//        </Grid>
//      </Grid>
//      <Grid item onClick={preventDefault}>
//        <Box
//          minWidth={80}
//          onClick={props.next}
//          visibility={props.next !== undefined ? "visible" : "hidden"}
//        >
//          <Grid
//            direction="column"
//            container
//            alignContent="center"
//            justifyContent="center"
//            height={"100%"}
//          >
//            <RightIcon width={"1rem"} />
//          </Grid>
//        </Box>
//      </Grid>
//    </Grid>
//  );
