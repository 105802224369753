/* eslint-disable jsx-a11y/alt-text */
import {
  Box,
  BoxProps,
  Grid,
  Typography,
  TypographyProps,
} from "@mui/material";
import {
  HorizontalGrid,
  VerticalGrid,
} from "../../components/HorizontalGrid/HorizontalGrid";
import { useRef, useState, useEffect, useMemo } from "react";
import { ZIndexes } from "../../components/InternalPage/InternalPage";
import { useDispatch } from "react-redux";
import { setYear } from "../../redux/history.reducer";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { CSSProperties } from "@mui/material/styles/createMixins";
import { useSpring, animated, useSpringRef } from "react-spring";
import { preventDefault } from "../../components/Modal";
import { easeInQuad, easeOutQuad, transitionEnabled } from "../../constants";
import { Center } from "../elite/digital_marketing";
import { VideoKeys } from "../../utils/assets";
import { useSingleAsset } from "../../components/AsssetsLoader";
import { openModal } from "../../redux/modal.reducer";
import { PlayIcon } from "../../components/Icons/PlayIcon";
import { isSafari } from "../../utils/functions";

type BaseElement = Pick<CSSProperties, "top" | "maxWidth"> & {
  leftOffset?: number;
  path?: string;
  config?: any;
};

const ages: Array<AgeCardProps> = [
  {
    year: 1908,
    content: (
      <>
        Merz Pharmaceuticals viene fondato da <strong>Friedrich Merz</strong>
      </>
    ),
  },
  {
    year: 1911,
    content: (
      <>
        <strong>Patentex</strong>
        <br /> topical contraceptive
      </>
    ),
  },
  {
    year: 1953,
    content: (
      <>
        <strong>Placentubex</strong>
        <br /> anti-aging skin care
      </>
    ),
  },
  {
    year: 1964,
    content: <strong>Merz Spezial Dragees</strong>,
  },
  {
    year: 1968,
    content: (
      <>
        <strong>Placentubex</strong> Foaming Mask
      </>
    ),
  },
  {
    year: 2008,
    content: (
      <>
        <strong>Bocouture®</strong> viene introdotto in Germania
      </>
    ),
  },
  {
    year: 2010,
    content: (
      <>
        <strong>Bioform BV</strong> (BE) viene acquisita con{" "}
        <strong>Radiesse®</strong>
      </>
    ),
  },
  {
    year: 2013,
    content: (
      <>
        <strong>Anteis SA</strong> (CH) viene acquisita con{" "}
        <strong>Belotero®</strong>
      </>
    ),
  },
  {
    year: 2014,
    content: (
      <>
        Acquisizione di <strong>Ulthera®</strong>
      </>
    ),
  },
  {
    year: 2020,
    content: (
      <>
        Merz Aesthetics è il <strong>più grande business al mondo</strong> in
        medicina estetica
      </>
    ),
  },
];

interface ImgElement extends BaseElement {
  type: "img";
  src: string;
}

interface IconElement extends BaseElement {
  type: "icon";
  icon: BoxProps["children"];
}

type Element = ImgElement | IconElement;

interface HistoryPageProps {
  years: Record<
    number,
    {
      elements: Element[];
    }
  >;
}
const time = "1s";

export function HistoryPage(props: HistoryPageProps) {
  const ref = useRef<HTMLDivElement>(null);
  const [rect, setRect] = useState<DOMRect | null>(null);
  const boxRef = useRef<HTMLDivElement>(null);
  const [boxRect, setBoxRect] = useState<DOMRect | null>(null);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (ref.current && boxRef.current) {
      setRect(ref.current.getBoundingClientRect());
      setBoxRect(boxRef.current.getBoundingClientRect());
    }
  }, []);

  const cardWidth = useMemo(() => {
    if (rect) {
      return (rect.width - 139) / 11;
    } else {
      return null;
    }
  }, [rect]);

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "100%",
          padding: "0px 3rem",
          overflow: "visible",
          color: "white",
        }}
        ref={ref}
        onClick={() => dispatch(setYear(null))}
      >
        <VerticalGrid type="container" rows={["auto", "30rem"]} sx={{ px: 4 }}>
          <VerticalGrid type="item" sx={{ overflow: "visible" }}>
            <Box
              sx={{ width: "100%", height: "100%", overflow: "visible" }}
              position={"relative"}
              ref={boxRef}
            >
              {boxRect && cardWidth && (
                <>
                  {Object.entries(props.years).map(([year, value], index) => {
                    return value.elements.map((e, i) => (
                      <OscillationElement
                        key={`${year}-${i}`}
                        id={parseInt(year)}
                        height={boxRect.height}
                        width={cardWidth}
                        left={index * cardWidth}
                        element={e}
                      />
                    ));
                  })}
                </>
              )}
            </Box>
          </VerticalGrid>
          <VerticalGrid type="item">
            {cardWidth && (
              <HorizontalGrid
                columnSize={cardWidth}
                columns={10}
                type="container"
                scrollable={false}
                gap={1}
                sx={{ height: "100%" }}
              >
                {ages.map((age, index) => (
                  <HorizontalGrid
                    key={age.year}
                    type="item"
                    sx={{
                      height: "100%",
                      animationName: "bottom-up",
                      animationDelay: `${index * 0.2 + 2}s`,
                      animationDuration: "2s",
                      animationTimingFunction: easeOutQuad,
                      animationFillMode: "both",
                    }}
                  >
                    <AgeCard {...age} />
                  </HorizontalGrid>
                ))}
              </HorizontalGrid>
            )}
          </VerticalGrid>
        </VerticalGrid>
      </div>
      <BottomHistory />
    </>
  );
}

function BottomHistory() {
  return (
    <Box
      height={"15rem"}
      width={"100%"}
      position={"absolute"}
      bottom={0}
      zIndex={ZIndexes.navigation}
    >
      <Grid container direction={"column"} height={"100%"}>
        <Grid item>
          <Grid container width={"100%"} justifyContent={"center"}>
            <Typography
              variant="h4"
              color={"white"}
              sx={{
                animation: `text-focus-in 1s ${easeInQuad}`,
                animationFillMode: "both",
              }}
            >
              <strong>
                <i style={{ fontSize: "2.5rem" }}>115</i> ANNI D'INNOVAZIONE
              </strong>{" "}
              NELLA <strong>RICERCA FARMACEUTICA</strong>
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs>
          <Grid
            container
            width={"100%"}
            justifyContent={"space-evenly"}
            alignContent={"center"}
            height={"100%"}
          >
            <BoxWithVideo
              video="chi_siamo_1"
              startTime={6}
              sx={{
                animationName: "left-right-dna",
                animationDelay: `${ages.length * 0.2 + 2}s`,
                animationDuration: "2s",
                animationTimingFunction: easeOutQuad,
                animationFillMode: "both",
              }}
            >
              <strong>Chi siamo</strong>
            </BoxWithVideo>
            <BoxWithVideo
              video="valori_merz"
              startTime={28.3}
              sx={{
                animationName: "bottom-up-dna",
                animationDelay: `${ages.length * 0.2 + 2}s`,
                animationDuration: "2s",
                animationTimingFunction: easeOutQuad,
                animationFillMode: "both",
              }}
            >
              I <strong>valori</strong> di Merz
            </BoxWithVideo>
            <BoxWithVideo
              video="why_merz"
              startTime={5}
              sx={{
                animationName: "right-left-dna",
                animationDelay: `${ages.length * 0.2 + 2}s`,
                animationDuration: "2s",
                animationTimingFunction: easeOutQuad,
                animationFillMode: "both",
              }}
            >
              Perché <strong>Merz</strong>
            </BoxWithVideo>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

function BoxWithVideo(props: {
  children: React.ReactNode;
  video: VideoKeys;
  startTime: number;
  sx?: BoxProps["sx"];
}) {
  const video = useSingleAsset(props.video);
  const dispatch = useAppDispatch();
  const ref = useRef<HTMLVideoElement>(null);

  const [rect, setRect] = useState<DOMRect | null>(null);

  useEffect(() => {
    if (ref.current) {
      setRect(ref.current.getBoundingClientRect());
    }
  }, []);

  return (
    <Box
      width={"20rem"}
      height={"6rem"}
      sx={{ backgroundColor: "white", p: 1, ...props.sx }}
    >
      {rect && (
        <Box
          position={"absolute"}
          zIndex={ZIndexes.body + 1}
          width={rect.width}
          height={rect.height}
          onClick={() => {
            dispatch(
              openModal({
                modalData: { type: "video", video: video! },
                rect: ref.current!.getBoundingClientRect(),
              })
            );
          }}
        >
          <Center>
            <PlayIcon height={"1.5rem"} />
          </Center>
        </Box>
      )}
      <Grid
        container
        height={"100%"}
        width={"100%"}
        justifyContent={"center"}
        alignContent={"center"}
      >
        <video
          ref={ref}
          src={video}
          height={"100%"}
          width={"auto"}
          onLoadedData={(e) =>
            ((e.target as HTMLVideoElement).currentTime = props.startTime ?? 0)
          }
        />
        <Grid item xs>
          <Center>
            <Typography variant="body1" sx={{ color: "#002FA2" }}>
              <i>{props.children}</i>
            </Typography>
          </Center>
        </Grid>
      </Grid>
    </Box>
  );
}

interface OscillationElementProps {
  id: number;
  left: number;
  width: number;
  height: number;
  element: Element;
}

export function OscillationElement(props: OscillationElementProps) {
  const selected = useAppSelector((state) => state.history.year === props.id);
  const dispatch = useDispatch();

  const api = useSpringRef();
  const springs = useSpring({
    ref: api,
    immediate: true,
    loop: true,
    from: { offsetDistance: "0%" },
    to: [{ offsetDistance: "100%" }, { offsetDistance: "0%" }],
    config: props.element.config,
  });

  useEffect(() => {
    setTimeout(() => api.start(), 100);
  }, [api]);

  useEffect(() => {
    if (selected) {
      api.pause();
    } else {
      api.resume();
    }
  }, [api, selected, springs.offsetDistance]);

  const random = useMemo(
    () =>
      //Gereate a random from 1 to 3
      Math.floor(Math.random() * 4),
    []
  );

  return (
    <animated.div
      onClick={(e) => {
        preventDefault(e);
        dispatch(setYear(selected ? null : props.id));
      }}
      style={{
        position: "absolute",
        animation: `blur 3s ease-out`,
        animationDelay: `${random}s`,
        animationFillMode: "both",
        left: props.left + (props.element.leftOffset ?? 0),
        zIndex: selected ? ZIndexes.body + 1 : ZIndexes.body,
        top: props.element.top,
        offsetAnchor: "left top",
        offsetPath: `path("${props.element.path}")`,
        offsetRotate: "0deg",
        ...springs,
      }}
    >
      <InnerEl {...props} />
    </animated.div>
  );
}

function InnerEl(props: OscillationElementProps) {
  const year = useAppSelector((state) => state.history.year);
  const selected = useMemo(() => year === props.id, [props.id, year]);
  const opacity = useMemo(() => (props.id === year ? 1 : 0), [props.id, year]);

  const el = useMemo(() => {
    switch (props.element.type) {
      case "img":
        return (
          <img
            src={props.element.src}
            style={{
              maxWidth: props.element.maxWidth ?? "100%",
              height: "auto",
            }}
            draggable={false}
          />
        );
      case "icon":
        return <Box children={props.element.icon} />;
    }
  }, [props.element]);

  return (
    <Box
      sx={{
        maxWidth: props.width,
        transform: selected ? "scale(2)" : "scale(1)",
        transition: !transitionEnabled
          ? undefined
          : `transform ${time} ease-in-out, opacity ${time} ease-in-out`,
        opacity,
      }}
      children={el}
    />
  );
}

interface AgeCardProps {
  year: number;
  content: TypographyProps["children"];
}

function AgeCard(props: AgeCardProps) {
  const visible = useAppSelector((state) => state.history.year === props.year);
  const dispatch = useDispatch();

  return (
    <Box
      width={"100%"}
      height={"100%"}
      onClick={(e) => {
        preventDefault(e);
        dispatch(setYear(visible ? null : props.year));
      }}
    >
      <VerticalGrid
        type="container"
        rows={["2rem", "6px", "auto"]}
        sx={{ position: "relative" }}
      >
        <VerticalGrid type="item">
          <Typography
            textAlign={"center"}
            position={"absolute"}
            top={0}
            width={"100%"}
            fontSize={visible ? "2rem" : "1.5rem"}
            zIndex={ZIndexes.body + 1}
            sx={{
              fontWeight: visible ? "bold" : "normal",
              transition:
                !transitionEnabled || isSafari()
                  ? undefined
                  : `font-size 0.5s linear`,
            }}
          >
            {props.year}
          </Typography>
        </VerticalGrid>
        <VerticalGrid
          type="item"
          sx={{ height: "100%", backgroundColor: "white" }}
        />
        <VerticalGrid
          type="item"
          sx={{
            height: "100%",
            background: "#8B8B8B6E 0% 0% no-repeat padding-box",
            opacity: "0.15",
          }}
        />
        <Box
          position={"absolute"}
          bgcolor={"#002FA2"}
          height={visible ? "100%" : "0%"}
          width={"100%"}
          sx={{
            transition: !transitionEnabled
              ? undefined
              : `height ${time} ease-in-out`,
          }}
          zIndex={ZIndexes.body}
        >
          <VerticalGrid
            type="container"
            rows={["2rem", "6px", "auto"]}
            sx={{ position: "relative" }}
          >
            <VerticalGrid type="item" />
            <VerticalGrid type="item" />
            <VerticalGrid
              type="item"
              sx={{
                height: "100%",
              }}
            >
              <Grid container height={"100%"} width={"100%"}>
                <Grid item px={1} pt={1}>
                  <Typography
                    fontSize={"1rem"}
                    textAlign={"center"}
                    sx={{ whiteSpace: "break-spaces" }}
                  >
                    {props.content}
                  </Typography>
                </Grid>
              </Grid>
            </VerticalGrid>
          </VerticalGrid>
        </Box>
      </VerticalGrid>
    </Box>
  );
}
