import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Prodotto } from "../constants/viso.constants";

type BeloterState = {
  index: number | null;
};

export const beloteroSlice = createSlice({
  name: "belotero",
  initialState: {
    index: null,
  } as BeloterState,
  reducers: {
    setIndex: (state, action: PayloadAction<number | null>) => {
      state.index = action.payload;
    },
    setBelotero: (state, action: PayloadAction<Prodotto>) => {
      switch (action.payload) {
        case Prodotto.BELOTERO_BALANCE:
          state.index = 2;
          break;
        case Prodotto.BELOTERO_SOFT:
          state.index = 1;
          break;
        case Prodotto.BELOTERO_INTENSE:
          state.index = 3;
          break;
        case Prodotto.BELOTERO_REVIVE:
          state.index = 0;
          break;
        case Prodotto.BELOTERO_VOLUME:
          state.index = 4;
          break;
        case Prodotto.BELOTERO_LIPS_CONTOUR:
          state.index = 5;
          break;
        case Prodotto.BELOTERO_LIPS_SHAPE:
          state.index = 5;
          break;
        default:
          state.index = null;
      }
    },
    resetBelotero: (state) => {
      state.index = null;
    },
  },
});

export const { setIndex, setBelotero, resetBelotero } = beloteroSlice.actions;

export default beloteroSlice.reducer;
