import { BASE_URL } from "./config";

export const videos = {
  chi_siamo_video: `${BASE_URL}/videos/home/chi_siamo.mp4`,
  elite_video: `${BASE_URL}/videos/home/elite.mp4`,
  i_nostri_prodotti_video: `${BASE_URL}/videos/home/i_nostri_prodotti.mp4`,
  servizi_video: `${BASE_URL}/videos/home/servizi.mp4`,
  i_nostri_prodotti_video_sfondo: `${BASE_URL}/videos/inostriprodotti/sfondo.mp4`,
  sfondo: `${BASE_URL}/videos/sfondo_intro.mp4`,
  standby_transition: `${BASE_URL}/videos/standby_transition.mp4`,
  radiesse_video_intro: `${BASE_URL}/videos/radiesse/intro.mp4`,
  radiesse_video_tecnologia: `${BASE_URL}/videos/radiesse/Radiesse_Meccanismo_dazione.mp4`,
  radiesse_timeline: `${BASE_URL}/videos/radiesse/TIMELINE_RADIESSE.mp4`,
  radiesse_classic0: `${BASE_URL}/videos/radiesse/RADIESSE_CLASSIC/biostimolazione.mp4`,
  radiesse_classic1: `${BASE_URL}/videos/radiesse/RADIESSE_CLASSIC/collo.mp4`,
  radiesse_classic2: `${BASE_URL}/videos/radiesse/RADIESSE_CLASSIC/decollete_ago.mp4`,
  radiesse_classic3: `${BASE_URL}/videos/radiesse/RADIESSE_CLASSIC/decollete_cannula.mp4`,
  radiesse_classic4: `${BASE_URL}/videos/radiesse/RADIESSE_CLASSIC/mani_cannula.mp4`,
  radiesse_lido0: `${BASE_URL}/videos/radiesse/RADIESSE_LIDOCAINE/angolo_mandibolare_rughe_marionetta_jawline.mp4`,
  radiesse_lido1: `${BASE_URL}/videos/radiesse/RADIESSE_LIDOCAINE/mani_ago.mp4`,
  radiesse_lido2: `${BASE_URL}/videos/radiesse/RADIESSE_LIDOCAINE/rughe_nasogeniene.mp4`,
  radiesse_lido3: `${BASE_URL}/videos/radiesse/RADIESSE_LIDOCAINE/zigomatica_con_ago.mp4`,
  radiesse_lido4: `${BASE_URL}/videos/radiesse/RADIESSE_LIDOCAINE/zigomatico_con_cannula.mp4`,
  radiesse_siringhe: `${BASE_URL}/videos/radiesse/Siringa01.mp4`,
  belotero_slider_caroline: `${BASE_URL}/videos/belotero/slider_carolin.mp4`,
  belotero_video_prodotto: `${BASE_URL}/videos/belotero/video_prodotto.mp4`,
  belotero_video_intro: `${BASE_URL}/videos/belotero/intro.mp4`,
  belotero_video_trattemento_revive: `${BASE_URL}/videos/belotero/Tunesi-Revive-SkinSaver.mp4`,
  belotero_video_trattemento_lips: `${BASE_URL}/videos/belotero/Gigliofiorito-Lips.mp4`,
  belotero_video_trattemento_balance: `${BASE_URL}/videos/belotero/Lanza-Balance.mp4`,
  belotero_video_trattemento_intense: `${BASE_URL}/videos/belotero/Lazzarotto-Intense_V4.mp4`,
  belotero_video_trattemento_volume: `${BASE_URL}/videos/belotero/Renzi-Volume.mp4`,
  belotero_video_trattemento_soft: `${BASE_URL}/videos/belotero/Tunesi-Soft-Blanching.mp4`,
  belotero_video_ba_carolin: `${BASE_URL}/videos/belotero/Belotero-BA2021_SoMe_Slider_Carolin.mp4`,
  belotero_video_ba_gordana: `${BASE_URL}/videos/belotero/Belotero-BA2021_SoMe_Slider_Gordana.mp4`,
  belotero_video_ba_jessica: `${BASE_URL}/videos/belotero/Belotero-BA2021_SoMe_Slider_Jessica_Jung.mp4`,
  belotero_video_ba_lidia: `${BASE_URL}/videos/belotero/Belotero-BA2021_SoMe_Slider_Lidia.mp4`,
  belotero_video_ba_marion: `${BASE_URL}/videos/belotero/Belotero-BA2021_SoMe_Slider_Marion.mp4`,
  belotero_video_ba_matthias: `${BASE_URL}/videos/belotero/Belotero-BA2021_SoMe_Slider_Matthias.mp4`,
  belotero_video_ba_milena: `${BASE_URL}/videos/belotero/Belotero-BA2021_SoMe_Slider_Milena.mp4`,
  belotero_video_ba_mirjana: `${BASE_URL}/videos/belotero/Belotero-BA2021_SoMe_Slider_Mirjana.mp4`,
  belotero_video_ba_weisser: `${BASE_URL}/videos/belotero/Belotero-BA2021_SoMe_Slider_Sandra_Weisser.mp4`,
  belotero_video_ba_sihan: `${BASE_URL}/videos/belotero/Belotero-BA2021_SoMe_Slider_Siham.mp4`,
  belotero_video_ba_simone: `${BASE_URL}/videos/belotero/Belotero-BA2021_SoMe_Slider_Simone.mp4`,
  belotero_video_ba_sina: `${BASE_URL}/videos/belotero/Belotero21_SoMe_Slider_Sina.mp4`,
  belotero_video_ba_solvita: `${BASE_URL}/videos/belotero/Belotero21_SoMe_Slider_Solvita.mp4`,
  belotero_reologia: `${BASE_URL}/videos/belotero/Video_Reologia_BELOTERO.mp4`,
  belotero_tecnologia: `${BASE_URL}/videos/belotero/Video_Tecnologia_BELOTERO.mp4`,
  bocoture_purezza: `${BASE_URL}/videos/bocoture/purezza.mp4`,
  ultherapy_video_intro: `${BASE_URL}/videos/ultherapy/Video_Ultherapy_2020_V3.mp4`,
  ultherapy_banner_sup: `${BASE_URL}/videos/ultherapy/banner_superiore.mp4`,
  ultherapy_banner_1: `${BASE_URL}/videos/ultherapy/banner_1.mp4`,
  ultherapy_banner_2: `${BASE_URL}/videos/ultherapy/banner_2.mp4`,
  ultherapy_collo: `${BASE_URL}/videos/ultherapy/collo.mp4`,
  ultherapy_decolte: `${BASE_URL}/videos/ultherapy/decolte.mp4`,
  ultherapy_viso: `${BASE_URL}/videos/ultherapy/viso.mp4`,
  ultherapy_method: `${BASE_URL}/videos/ultherapy/Ultherapy_Method_of_Action_Video.mp4`,
  ultherapy_icons: `${BASE_URL}/videos/ultherapy/Ultherapy_Icons03.mov`,
  ultherapy_icons_webm: `${BASE_URL}/videos/ultherapy/ultherapy_icons.webm`,
  biorepeel_info: `${BASE_URL}/videos/biorepeel/tecnologia.mp4`,
  biorepeel_tecnologia: `${BASE_URL}/videos/biorepeel/prodotto_video_intro1.mp4`,
  biorepeel_addome: `${BASE_URL}/videos/biorepeel/addome.mp4`,
  biorepeel_composizione: `${BASE_URL}/videos/biorepeel/composizione.mp4`,
  biorepeel_come_si_applica0: `${BASE_URL}/videos/biorepeel/Come_si_applica.mp4`,
  biorepeel_come_si_applica1: `${BASE_URL}/videos/biorepeel/Come_si_applica_2.mp4`,
  biorepeel_trattamenti1: `${BASE_URL}/videos/biorepeel/corso_paz_1_drcavallini.mp4`,
  biorepeel_trattamenti2: `${BASE_URL}/videos/biorepeel/corso_paz_2_drcavallini.mp4`,
  biorepeel_intro: `${BASE_URL}/videos/biorepeel/biorepeel_tessere.mov`,
  biorepeel_intro_webm: `${BASE_URL}/videos/biorepeel/biorepeel_tessere.webm`,
  biorepeel_scopri_di_piu: `${BASE_URL}/videos/biorepeel/Scopri_di_piu_post_trattamento.mp4`,
  servizi_formazione: `${BASE_URL}/videos/servizi/FORMAZIONE.mp4`,
  servizi_marketing: `${BASE_URL}/videos/servizi/MARKETING_E_DIGITAL.mp4`,
  servizi_eventi: `${BASE_URL}/videos/servizi/VIDEO_EVENTI.mp4`,
  servizi_medical: `${BASE_URL}/videos/servizi/MEDICAL_AFFAIRS.mp4`,
  servizi_app: `${BASE_URL}/videos/servizi/qr_code.mp4`,
  servizi_sfondo: `${BASE_URL}/videos/servizi/servizi_sfondo.mp4`,
  chi_siamo_sfondo: `${BASE_URL}/videos/chi_siamo/chi_siamo.mp4`,
  chi_siamo_1: `${BASE_URL}/videos/chi_siamo/chi_siamo_1.mp4`,
  why_merz: `${BASE_URL}/videos/chi_siamo/why_merz.mp4`,
  valori_merz: `${BASE_URL}/videos/chi_siamo/valori_merz.mp4`,
  viso_muscles: `${BASE_URL}/videos/viso/muscles.mov`,
  viso_muscles_webm: `${BASE_URL}/videos/viso/muscles.webm`,
  elite_sfondo: `${BASE_URL}/videos/elite/sfondo.mp4`,
  elite_joint_sfondo: `${BASE_URL}/videos/elite/joint.mp4`,
  elite_intro: `${BASE_URL}/videos/elite/elite_video_original.mp4`,
  elite_advanced_training: `${BASE_URL}/videos/elite/ADVANCED_TRAINING.mp4`,
  elite_imclass_video_1: `${BASE_URL}/videos/elite/imcass/dubai.mp4`,
  elite_imclass_video_2: `${BASE_URL}/videos/elite/imcass/aftermovie.mp4`,
  elite_imexs_video_1: `${BASE_URL}/videos/elite/imexs/vienna_2022.mp4`,
  elite_itmexs_video_1: `${BASE_URL}/videos/elite/mexs/summit.mp4`,
  elite_amcw_video_1: `${BASE_URL}/videos/elite/amwc/highlight.mp4`,
  elite_imcas_video_1: `${BASE_URL}/videos/elite/imcas/highlight.mp4`,
  elite_face_to_face: `${BASE_URL}/videos/elite/OneToOne_Lab_Inietta_Bella.mp4`,
  elite_in_clinic: `${BASE_URL}/videos/elite/IN_CLINIC.mp4`,
  elite_digital_marketing: `${BASE_URL}/videos/elite/DIGITAL_MARKETING.mp4`,
  elite_content_creation_video: `${BASE_URL}/videos/elite/CONTENT_CREATION.mp4`,
  elite_scientific_network: `${BASE_URL}/videos/elite/SCIENTIFIC_NETWORK.mp4`,
  elite_merz_care: `${BASE_URL}/videos/elite/schermata2_4/merz_care.mp4`,
  elite_innovation_forum: `${BASE_URL}/videos/elite/schermata2_4/innovation_forum_2022.mp4`,
  elite_joint_business_plan: `${BASE_URL}/videos/elite/JOINT_BUSINESS_PLAN.mp4`,
};

export type VideoKeys = keyof typeof videos;
