/* eslint-disable jsx-a11y/alt-text */
import { Box, Grid, Typography } from "@mui/material";
import { SVGProps, useMemo } from "react";
import {
  InternalPage,
  ZIndexes,
} from "../../components/InternalPage/InternalPage";
import {
  useIsRouteVisible,
  useRouter,
} from "../../components/MainRouter/MainRouter";
import { ImageKeys } from "../../utils/assets";
import { Routes } from "../../utils/routes";
import { useCallback, useEffect, useRef, useState } from "react";
import { TextCard } from "../../components/Card/TextCard";
import { Line } from "../../components/Atoms/Lines";
import { PDFVisualizer } from "../../components/Card/PDFVisualizer";
import {
  HorizontalGrid,
  VerticalGrid,
} from "../../components/HorizontalGrid/HorizontalGrid";
import { useWindowSize } from "@uidotdev/usehooks";
import { VideoKeys } from "../../utils/assets";
import { RadiesseLogo } from "../../components/Logos/RadiesseLogo";
import { Radiesse } from "../../components/Logos/Radiesse";
import { GenericCard } from "../../components/Card/GenericCard";
import { NewVerticalScrollableCard } from "../../components/Card/NewVerticalScrollableCard";
import { KeyMessages } from "../../components/KeyMessages";
import { BeforeAndAfter } from "../../components/Card/BeforeAndAfter";
import { useAppDispatch } from "../../redux/hooks";
import { Rect, openModal } from "../../redux/modal.reducer";
import { DoubleElement } from "../../components/Card/DoubleElement";
import { preventDefault } from "../../components/Modal";
import { RadiessePlus } from "../../components/Logos/RadiessePlusLogo";
import { useAsset, useSingleAsset } from "../../components/AsssetsLoader";
import { LeftIcon, RightIcon } from "../../components/Icons/ArrowUP";
import { BackgroundIntro, Header, Inner } from "./BioRepeelPage";
import {
  focusInAnimation,
  prodottiInAnimation,
  transitionEnabled,
} from "../../constants";
import { useHandleIntro } from "./BeloteroIntroProdotti";

export function RadiessePage() {
  const { changeRoute } = useRouter();
  const isVisible = useIsRouteVisible(Routes.radiesse);
  const [videoIntro] = useAsset<VideoKeys>(["radiesse_video_intro"]);

  if (!isVisible) return <></>;

  return (
    <InternalPage
      color="radiesseBlue"
      logoFill="black"
      backgroundColor="white"
      goUpper={() =>
        changeRoute(Routes.inostriprodotti, { showPrevious: false })
      }
      goBack={() => changeRoute(Routes.belotero, { showPrevious: false })}
      goNext={() => changeRoute(Routes.bocoture, { showPrevious: false })}
      sectionHeader={
        <Header
          id="radiesse"
          color="radiesseBlue"
          children={
            <Typography
              variant="h3"
              textAlign="center"
              sx={{ color: "radiesseBlue.main" }}
            >
              <RadiesseLogo width={"16rem"} />
            </Typography>
          }
        />
      }
    >
      <BackgroundIntro id="radiesse" type="video" src={videoIntro} />
      <Inner id="radiesse" children={<Core />} color="radiesseBlue" />
    </InternalPage>
  );
}

function Core() {
  const show = useHandleIntro("radiesse", "after");
  return (
    <Box position={"fixed"} top={0} left={0} width={"100%"} height={"100%"}>
      <VerticalGrid type="container" rows={["70%", "30%"]}>
        <VerticalGrid type="item">
          <VerticalGrid type="container" rows={["8rem", "5rem", "auto"]}>
            <VerticalGrid type="item" />
            <VerticalGrid type="item">
              <Grid
                container
                direction={"row"}
                justifyContent={"center"}
                sx={{ whiteSpace: "break-spaces", color: "radiesseText.main" }}
              >
                <Grid item xs={6}>
                  {show && (
                    <Typography
                      variant="body1"
                      textAlign={"center"}
                      sx={{
                        ...focusInAnimation,
                      }}
                    >
                      <strong>
                        Filler a base di idrossiapatite di calcio per la
                        definizione delle linee del volto e la stimolazione del
                        collagene.
                      </strong>
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </VerticalGrid>
            <VerticalGrid
              type="item"
              children={show ? <RadiesseTop /> : <></>}
            />
          </VerticalGrid>
        </VerticalGrid>
        <VerticalGrid type="item" children={<RadiesseBottom />} />
      </VerticalGrid>
    </Box>
  );
}

function RadiesseTop() {
  const [radiesse_prodotto, radiesse_plus_prodotto, radiesse_bollino] =
    useAsset<ImageKeys>([
      "radiesse_prodotto",
      "radiesse_plus_prodotto",
      "radiesse_bollino",
    ]);

  const rigthRef = useRef<HTMLDivElement>(null);
  const leftRef = useRef<HTMLDivElement>(null);

  const [rightRect, setRightRect] = useState<DOMRect | undefined>();
  const [leftRect, setLeftRect] = useState<DOMRect | undefined>();

  useEffect(() => {
    if (rigthRef.current === null || leftRef.current === null) return;
    setRightRect(rigthRef.current.getBoundingClientRect());
    setLeftRect(leftRef.current.getBoundingClientRect());
  }, []);

  const [selected, setSelected] = useState<"left" | "right" | undefined>();

  useEffect(() => {
    if (rigthRef.current === null || leftRef.current === null) return;
    setRightRect(rigthRef.current.getBoundingClientRect());
    setLeftRect(leftRef.current.getBoundingClientRect());
  }, [selected]);

  const bolliniPosition = useMemo(() => {
    if (leftRect === undefined || rightRect === undefined) return undefined;
    const width = rightRect.right - leftRect.left;
    return leftRect.left + width * 0.45;
  }, [leftRect, rightRect]);

  return (
    <>
      {leftRect && (
        <Grid
          container
          height={leftRect.height}
          width={leftRect.width}
          left={leftRect.left}
          top={leftRect.top}
          zIndex={ZIndexes.body + 1}
          justifyContent={"center"}
          alignContent={"center"}
          position={"fixed"}
          style={{
            transition: !transitionEnabled ? undefined : "all 1s ease-out",
          }}
        >
          <img
            style={{
              ...prodottiInAnimation,
              height: "100%",
              width: "auto",
              opacity: selected !== undefined && selected !== "left" ? 0.5 : 1,
            }}
            onClick={(e) => {
              setSelected((selected) =>
                selected === "left" ? undefined : "left"
              );
              preventDefault(e);
            }}
            src={radiesse_prodotto}
          />
        </Grid>
      )}
      {rightRect && (
        <Grid
          container
          height={rightRect.height}
          width={rightRect.width}
          left={rightRect.left}
          top={rightRect.top}
          zIndex={ZIndexes.body + 1}
          justifyContent={"center"}
          alignContent={"center"}
          position={"fixed"}
          style={{
            transition: !transitionEnabled ? undefined : "all 1s ease-out",
          }}
        >
          <img
            style={{
              ...prodottiInAnimation,
              height: "100%",
              width: "auto",
              opacity: selected !== undefined && selected !== "right" ? 0.5 : 1,
            }}
            onClick={(e) => {
              setSelected((selected) =>
                selected === "right" ? undefined : "right"
              );
              preventDefault(e);
            }}
            src={radiesse_plus_prodotto}
          />
        </Grid>
      )}

      {bolliniPosition && (
        <img
          style={{
            ...prodottiInAnimation,
            position: "absolute",
            width: "4rem",
            left: bolliniPosition,
            zIndex: ZIndexes.body + 1,
            transition: !transitionEnabled ? undefined : "all 1s ease-out",
          }}
          src={radiesse_bollino}
        />
      )}
      {selected !== undefined && (
        <Box
          position={"fixed"}
          left={leftRect?.left}
          height={leftRect?.height}
          sx={{
            ...focusInAnimation,
            marginLeft: "-20em",
            width: "20rem",
            opacity: selected !== "left" ? 0.5 : 1,
          }}
        >
          <VerticalGrid type="container" rows={["50%", "50%"]}>
            <VerticalGrid type="item" sx={{ color: "radiesseBlue.main" }}>
              <Grid container direction={"row"} justifyContent={"flex-end"}>
                <Grid item width={"20rem"}>
                  <Grid container justifyContent={"right"} width={"100%"}>
                    <RadiesseLogo height={"1.5rem"} />
                  </Grid>
                  <Typography variant="h6" textAlign={"right"} pt={3}>
                    <i>Global Collagen Biostimulation</i>
                  </Typography>
                  <Typography textAlign={"right"}>
                    Consigliato per la biostimolazione di aree ampie; per una
                    bioristrutturazione completa della pelle ed una skin quality
                    che migliora progressivamente nel tempo. <sub>4,9-11</sub>
                  </Typography>
                </Grid>
              </Grid>
            </VerticalGrid>
            <VerticalGrid type="item">
              <VideoList
                videos={Array(5)
                  .fill(0)
                  .map((_, i) => `radiesse_classic${i}` as VideoKeys)}
              />
            </VerticalGrid>
          </VerticalGrid>
        </Box>
      )}
      {selected !== undefined && (
        <Box
          position={"fixed"}
          left={(rightRect?.right ?? 0) + 10}
          height={rightRect?.height}
          sx={{
            ...focusInAnimation,
            width: `20rem`,
            opacity: selected !== "right" ? 0.5 : 1,
          }}
        >
          <VerticalGrid type="container" rows={["50%", "50%"]}>
            <VerticalGrid type="item" sx={{ color: "radiesseBlue.main" }}>
              <Grid
                container
                direction={"row"}
                justifyContent={"flex-start"}
                width={"20rem"}
              >
                <Grid container width={"100%"}>
                  <RadiessePlus height={"2.8rem"} />
                </Grid>
                <Typography variant="h6" pt={3}>
                  <i>Focal Collagen Biostimulation</i>
                </Typography>
                <Typography>
                  Consigliato per la definizione di aree specifiche del volto,
                  in particolare la linea mandibolare. Effetto lifting verticale
                  e contouring long lasting.<sub>12-14</sub>
                </Typography>
              </Grid>
            </VerticalGrid>
            <VerticalGrid type="item">
              <VideoList
                videos={Array(5)
                  .fill(0)
                  .map((_, i) => `radiesse_lido${i}` as VideoKeys)}
              />
            </VerticalGrid>
          </VerticalGrid>
        </Box>
      )}
      <Grid
        container
        direction={"row"}
        justifyContent={"center"}
        height={"100%"}
        overflow={"hidden"}
      >
        {selected && <Grid item xs={4}></Grid>}
        <Grid item xs={4} height={"100%"}>
          <Grid container direction={"column"} height={"100%"}>
            <Grid
              container
              direction={"row"}
              height={"100%"}
              position={"relative"}
            >
              <Grid item xs={6} height={"100%"}>
                <div
                  ref={leftRef}
                  style={{
                    width: "100%",
                    height: "90%",
                    visibility: "hidden",
                  }}
                ></div>
              </Grid>
              <Grid item xs={6}>
                <div
                  ref={rigthRef}
                  style={{
                    width: "100%",
                    height: "90%",
                    visibility: "hidden",
                  }}
                ></div>
              </Grid>

              {selected && (
                <Typography
                  position={"absolute"}
                  variant="caption"
                  zIndex={ZIndexes.body + 1}
                  bottom={"0"}
                  textAlign={"center"}
                  width={"100%"}
                  color={"#8A8A8A"}
                  // fontSize={6}
                  paddingBottom={2}
                >
                  4. Moers-Carpi, M. et al. Dermatol Surg 2007;33(Suppl
                  2):S144–151. ; 9. Zerbinati N. Clin Cosmet Investig Dermatol.
                  2018;11:29-35. <br />
                  10. Sundaram H, et al. Dermatol Surg. 2010;36 Suppl 3:1859-65;
                  11. Cogorno Wasylkowski V. Clin Cosmet Investig Dermatol.
                  2015;8:267-73 <br />
                  12. Schachter D et al. J Drugs Dermatol. 2016;15(8):1005-10.
                  13. Bass LS, Smith S, Busso M, McClaren M. Aesthetic Surgery
                  J. 2010; 30(2):235-238. <br />
                  14. Yutskovskaya YA, et al. J Drugs Dermatol.
                  2017;16(1):68-74.
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>

        {selected && <Grid item xs={4}></Grid>}
        <VideoTimeline showVideo={selected === undefined} />
      </Grid>
    </>
  );
}

interface VideoTimelineProps {
  showVideo: boolean;
}

function VideoTimeline({ showVideo }: VideoTimelineProps) {
  const timeline = useSingleAsset<VideoKeys>("radiesse_timeline");

  return (
    <Grid
      item
      xs={showVideo ? 6 : 0}
      sx={{
        position: "relative",
        display: showVideo ? "" : "none",
      }}
      height={"100%"}
    >
      <video
        crossOrigin="anonymous"
        autoPlay
        disablePictureInPicture
        src={timeline}
        style={{
          maxHeight: "auto",
          marginTop: "3rem",
          width: "100%",
          objectFit: "contain",
          position: "absolute",
          top: 0,
        }}
      />
    </Grid>
  );
}

function RadiesseBottom() {
  const [selected, setSelected] = useState<number>(0);
  return (
    <NewVerticalScrollableCard
      color="radiesseDots"
      selected={selected}
      onSelectedIndexChange={setSelected}
      sx={{
        color: "black",
        backgroundColor: "bgGrey.light",
      }}
      elements={[
        {
          element: (
            <KeyMessages
              color="radiesseText"
              description={
                <Grid
                  container
                  direction={"row"}
                  gap={1}
                  justifyContent={"center"}
                  sx={{ ...focusInAnimation }}
                >
                  <Grid item alignSelf={"center"}>
                    <Typography variant="body1">
                      Radiesse è un prodotto a triplice effetto
                    </Typography>
                  </Grid>
                  <Radiesse height={"2rem"} />
                </Grid>
              }
              messages={[
                {
                  type: "icon",
                  title: <strong>Biostimolazione generativa</strong>,
                  icon: <BioStimolazioneIcon width={"4rem"} fill="#1aafcd" />,
                },
                {
                  type: "icon",
                  title: <strong>Lifting verticale immediato</strong>,
                  icon: <LiftingIcon width={"4rem"} fill="#1aafcd" />,
                },
                {
                  type: "icon",
                  title: (
                    <strong>
                      Contouring e definizione
                      <br /> del terzo inferiore del volto
                    </strong>
                  ),
                  icon: <ContouringIcon width={"4rem"} fill="#1aafcd" />,
                },
              ]}
            />
          ),
        },
        {
          element: <RadiessePage2 />,
        },
      ]}
    />
  );
}

function RadiessePage2() {
  const { width } = useWindowSize();
  const [
    radiesse_christian_after,
    radiesse_christian_before,
    radiesse_meccanismo_dazione0,
  ] = useAsset<ImageKeys>([
    "radiesse_christian_after",
    "radiesse_christian_before",
    "radiesse_meccanismo_dazione0",
  ]);

  const [radiesse_video_tecnologia, radiesse_siringhe] = useAsset<VideoKeys>([
    "radiesse_video_tecnologia",
    "radiesse_siringhe",
  ])!;

  const ref = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();

  const open = useCallback(
    (initial: number, rect: Rect, id?: string) => () => {
      dispatch(
        openModal({
          modalData: {
            type: "carousel",
            initial,
            items: [
              { type: "image", src: radiesse_meccanismo_dazione0 },
              { type: "video", src: radiesse_video_tecnologia },
            ],
          },
          animated: false,
          rect,
          id,
        })
      );
    },
    [dispatch, radiesse_meccanismo_dazione0, radiesse_video_tecnologia]
  );
  if (width === null) return <></>;

  return (
    <Grid
      container
      justifyContent={"center"}
      height={"100%"}
      pl={7}
      direction={"row"}
      alignContent={"center"}
      ref={ref}
    >
      <HorizontalGrid
        type="container"
        gap={1}
        firstColumn={`${width * 0.1}px`}
        columnSize={width * 0.2}
        columns={4}
        sx={{
          height: "80%",
        }}
      >
        <HorizontalGrid type="item">
          <TextCard title="Before & After" color="radiesseBlue">
            <GenericCard
              color="radiesseBlue"
              mediaChild={(rect) => (
                <Grid
                  container
                  justifyContent={"center"}
                  style={{ width: "100%", height: rect.height }}
                >
                  <Grid item style={{ width: `${rect.height * 0.6}px` }}>
                    <BeforeAndAfter
                      rect={rect}
                      before={radiesse_christian_before}
                      after={radiesse_christian_after}
                    />
                  </Grid>
                </Grid>
              )}
              onClick={(rect) => {
                if (rect === undefined) return;
                dispatch(
                  openModal({
                    modalData: {
                      type: "beforeAndAfter",
                      images: [
                        "radiesse_christian",
                        "radiesse_egle",
                        "radiesse_ewa",
                        "radiesse_irene",
                      ],
                    },
                    rect,
                  })
                );
              }}
            >
              <Line color="radiesseBlue" height={6} />
            </GenericCard>
          </TextCard>
        </HorizontalGrid>
        <HorizontalGrid type="item">
          <TextCard title="Composizione" color="radiesseBlue">
            <GenericCard video={radiesse_siringhe} startTime={5}>
              <Line color="radiesseBlue" height={6} />
            </GenericCard>
          </TextCard>
        </HorizontalGrid>
        <HorizontalGrid type="item">
          <TextCard title="Come agisce" color="radiesseBlue">
            <GenericCard
              mediaChild={(rect, id) => (
                <DoubleElement
                  right={
                    <img
                      src={radiesse_meccanismo_dazione0}
                      style={{
                        width: rect.width,
                        height: "auto",
                        objectFit: "cover",
                      }}
                      onClick={open(0, rect, id)}
                    />
                  }
                  left={
                    <video
                      crossOrigin="anonymous"
                      src={radiesse_video_tecnologia}
                      style={{
                        width: rect.width,
                        height: "auto",
                        objectFit: "cover",
                      }}
                      disablePictureInPicture
                      disableRemotePlayback
                      controls={false}
                      onClick={open(1, rect, id)}
                    />
                  }
                />
              )}
            >
              <Line color="radiesseBlue" height={6} />
            </GenericCard>
          </TextCard>
        </HorizontalGrid>
        <HorizontalGrid type="item">
          <TextCard title="Tecnologia" color="radiesseBlue">
            <PDFVisualizer
              image="radiesse_tecnologia"
              color="radiesseBlue"
              pages={2}
              bgColor="white"
            />
          </TextCard>
        </HorizontalGrid>
      </HorizontalGrid>
    </Grid>
  );
}
const BioStimolazioneIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Livello_1"
    data-name="Livello 1"
    viewBox="0 0 82 82"
    width={"4rem"}
    {...props}
  >
    <g>
      <g id="Raggruppa_1648" data-name="Raggruppa 1648">
        <path
          id="Tracciato_598"
          d="M41 82.04c-22.64 0-41-18.36-41-41s18.36-41 41-41 41 18.36 41 41c-.02 22.63-18.37 40.98-41 41m0-79.82C19.56 2.22 2.18 19.6 2.18 41.04S19.56 79.86 41 79.86s38.82-17.38 38.82-38.82C79.8 19.61 62.43 2.25 41 2.22"
          data-name="Tracciato 598"
        />
        <path
          id="Tracciato_599"
          d="M69.78 43.05H12.23v-2.18h5.15v-9.95h-5.15v-2.18h57.54v2.18h-4.94v9.95h4.94v2.18Zm-16.26-2.18h9.13v-9.95h-9.13v9.95Zm-11.32 0h9.13v-9.95h-9.14v9.95Zm-11.32 0h9.13v-9.95h-9.13v9.95Zm-11.32 0h9.13v-9.95h-9.14v9.95Z"
          data-name="Tracciato 599"
        />
        <path
          id="Tracciato_600"
          d="M19.67 50.92c0 .9-.72 1.62-1.62 1.62-.9 0-1.62-.72-1.62-1.62 0-.9.72-1.62 1.62-1.62.89 0 1.62.72 1.62 1.62"
          data-name="Tracciato 600"
        />
        <path
          id="Tracciato_601"
          d="M29.42 49.3a1.62 1.62 0 1 1-1.62 1.62c0-.89.72-1.62 1.61-1.62h.01"
          data-name="Tracciato 601"
        />
        <path
          id="Tracciato_602"
          d="M40.79 49.3c.9 0 1.62.72 1.62 1.62s-.72 1.62-1.62 1.62c-.9 0-1.62-.72-1.62-1.62 0-.89.72-1.62 1.62-1.62"
          data-name="Tracciato 602"
        />
        <path
          id="Tracciato_603"
          d="M52.17 49.3c.9 0 1.62.72 1.62 1.62 0 .9-.72 1.62-1.62 1.62-.9 0-1.62-.72-1.62-1.62 0-.89.72-1.62 1.62-1.62"
          data-name="Tracciato 603"
        />
        <path
          id="Tracciato_604"
          d="M63.54 49.3c.9 0 1.62.72 1.62 1.62 0 .9-.72 1.62-1.62 1.62-.9 0-1.62-.72-1.62-1.62 0-.89.72-1.62 1.62-1.62"
          data-name="Tracciato 604"
        />
        <path
          id="Tracciato_605"
          d="M25.35 57.5c0 .9-.72 1.62-1.62 1.62-.9 0-1.62-.72-1.62-1.62 0-.9.72-1.62 1.62-1.62.89 0 1.62.72 1.62 1.62"
          data-name="Tracciato 605"
        />
        <path
          id="Tracciato_606"
          d="M35.11 55.88c.9 0 1.62.72 1.62 1.62 0 .9-.72 1.62-1.62 1.62-.9 0-1.62-.72-1.62-1.62 0-.89.72-1.62 1.62-1.62"
          data-name="Tracciato 606"
        />
        <path
          id="Tracciato_607"
          d="M46.48 55.88c.9 0 1.62.72 1.62 1.62 0 .9-.72 1.62-1.62 1.62-.9 0-1.62-.72-1.62-1.62 0-.89.72-1.62 1.62-1.62"
          data-name="Tracciato 607"
        />
        <path
          id="Tracciato_608"
          d="M57.86 55.88c.9 0 1.62.72 1.62 1.62 0 .9-.72 1.62-1.62 1.62-.9 0-1.62-.72-1.62-1.62 0-.89.72-1.62 1.62-1.62"
          data-name="Tracciato 608"
        />
      </g>
    </g>
  </svg>
);

const ContouringIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Livello_1"
    data-name="Livello 1"
    viewBox="0 0 82 82.04"
    {...props}
  >
    <g id="Raggruppa_1647" data-name="Raggruppa 1647">
      <g id="Raggruppa_1646" data-name="Raggruppa 1646">
        <path
          id="Tracciato_587"
          d="M41 82.02c-22.65 0-41-18.36-41-41s18.35-41 41-41 41 18.36 41 41c-.02 22.63-18.37 40.98-41 41M41 2.2C19.56 2.2 2.18 19.58 2.18 41.02S19.56 79.84 41 79.84s38.82-17.38 38.82-38.82C79.8 19.59 62.43 2.23 41 2.2"
          data-name="Tracciato 587"
        />
        <path
          id="Tracciato_588"
          d="M19.66 50.9c0 .9-.72 1.62-1.62 1.62-.9 0-1.62-.72-1.62-1.62 0-.9.72-1.62 1.62-1.62.89 0 1.62.72 1.62 1.62"
          data-name="Tracciato 588"
        />
        <path
          id="Tracciato_589"
          d="M29.42 49.29a1.62 1.62 0 1 1-1.62 1.62c0-.89.72-1.62 1.61-1.62h.01"
          data-name="Tracciato 589"
        />
        <path
          id="Tracciato_590"
          d="M40.79 49.29c.9 0 1.62.72 1.62 1.62s-.72 1.62-1.62 1.62c-.9 0-1.62-.72-1.62-1.62 0-.89.72-1.62 1.62-1.62"
          data-name="Tracciato 590"
        />
        <path
          id="Tracciato_591"
          d="M52.17 49.29c.9 0 1.62.72 1.62 1.62 0 .9-.72 1.62-1.62 1.62-.9 0-1.62-.72-1.62-1.62 0-.89.72-1.62 1.62-1.62"
          data-name="Tracciato 591"
        />
        <path
          id="Tracciato_592"
          d="M63.54 49.29c.9 0 1.62.72 1.62 1.62 0 .9-.72 1.62-1.62 1.62-.9 0-1.62-.72-1.62-1.62 0-.89.72-1.62 1.62-1.62"
          data-name="Tracciato 592"
        />
        <path
          id="Tracciato_593"
          d="M25.35 57.48c0 .9-.72 1.62-1.62 1.62-.9 0-1.62-.72-1.62-1.62 0-.9.72-1.62 1.62-1.62.89 0 1.62.72 1.62 1.62"
          data-name="Tracciato 593"
        />
        <path
          id="Tracciato_594"
          d="M35.11 55.86c.9 0 1.62.72 1.62 1.62 0 .9-.72 1.62-1.62 1.62-.9 0-1.62-.72-1.62-1.62 0-.89.72-1.62 1.62-1.62"
          data-name="Tracciato 594"
        />
        <path
          id="Tracciato_595"
          d="M46.48 55.86c.9 0 1.62.72 1.62 1.62 0 .9-.72 1.62-1.62 1.62-.9 0-1.62-.72-1.62-1.62 0-.89.72-1.62 1.62-1.62"
          data-name="Tracciato 595"
        />
        <path
          id="Tracciato_596"
          d="M57.86 55.86c.9 0 1.62.72 1.62 1.62 0 .9-.72 1.62-1.62 1.62-.9 0-1.62-.72-1.62-1.62 0-.89.72-1.62 1.62-1.62"
          data-name="Tracciato 596"
        />
        <path
          id="Tracciato_597"
          d="M69.77 43.03H12.22v-2.18h57.55v2.18Zm-56.99-8.42-1.1-1.88c.54-.32 13.56-7.87 29.12-7.87 10.35.13 20.51 2.83 29.55 7.88l-1.13 1.87a61.177 61.177 0 0 0-28.41-7.55c-14.97 0-27.88 7.49-28.01 7.57"
          data-name="Tracciato 597"
        />
      </g>
    </g>
  </svg>
);

const LiftingIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Livello 1"
    viewBox="0 0.02 82 82.03"
    {...props}
  >
    <g data-name="Raggruppa 1645">
      <g
        data-name="Raggruppa 1645-2"
        style={{
          clipPath: "url(#a)",
        }}
      >
        <path
          d="M41 82.02c-22.65 0-41-18.36-41-41s18.35-41 41-41 41 18.36 41 41c-.02 22.63-18.37 40.98-41 41M41 2.2C19.56 2.2 2.18 19.58 2.19 41.02c0 21.44 17.38 38.82 38.82 38.81 21.44 0 38.81-17.38 38.81-38.82C79.8 19.58 62.43 2.22 41 2.19m19.89 65.42H58.7V53.2l-3.97 3.97-1.54-1.54 6.6-6.6 6.6 6.6-1.55 1.54-3.96-3.96v14.4Zm-18.25 0h-2.18v-14.4l-3.96 3.97-1.55-1.55 6.6-6.6 6.6 6.6-1.54 1.54-3.97-3.97v14.41Zm-18.25 0H22.2v-14.4l-3.97 3.97-1.53-1.55 6.6-6.6 6.6 6.6-1.55 1.54-3.97-3.97v14.41Zm43.03-26.39c-4.12 0-8.2-.92-11.92-2.68a31.696 31.696 0 0 0-13.95-2.94c-4.69-.09-9.34.87-13.62 2.81a31.323 31.323 0 0 1-13.34 2.82v-2.18c4.32.05 8.6-.85 12.52-2.66 4.53-2.04 9.46-3.06 14.44-2.97 5.1-.09 10.15.98 14.78 3.11 3.46 1.66 7.25 2.52 11.09 2.52v2.18Zm0-7.31c-4.12 0-8.2-.92-11.92-2.68a31.696 31.696 0 0 0-13.95-2.94c-4.69-.09-9.34.87-13.62 2.81a31.323 31.323 0 0 1-13.34 2.82v-2.19c4.32.05 8.6-.86 12.52-2.66 4.53-2.04 9.46-3.06 14.44-2.97 5.1-.09 10.15.98 14.78 3.11 3.46 1.66 7.25 2.52 11.09 2.52v2.18Z"
          data-name="Tracciato 586"
          style={{
            fill: "#1aafcd",
          }}
        />
      </g>
    </g>
  </svg>
);

interface VideoListProps {
  videos: VideoKeys[];
}
function VideoList(props: VideoListProps) {
  const videos = useAsset(props.videos);
  const ref = useRef<HTMLDivElement>(null);
  const [selected, setSelected] = useState(0);

  const dispatch = useAppDispatch();

  const next = useCallback(() => {
    if (ref.current === null) return;
    const next = (selected + 1) % videos.length;
    setSelected(next);
    ref.current.scrollTo({
      left: next * ref.current.clientWidth,
      behavior: "smooth",
    });
  }, [selected, videos.length]);

  const previous = useCallback(() => {
    if (ref.current === null) return;
    const next = (selected - 1 + videos.length) % videos.length;
    setSelected(next);
    ref.current.scrollTo({
      left: next * ref.current.clientWidth,
      behavior: "smooth",
    });
  }, [selected, videos.length]);

  return (
    <VerticalGrid type="container" rows={["12.65625", "3rem"]}>
      <div
        ref={ref}
        style={{
          overflowX: "hidden",
          overflowY: "scroll",
          display: "flex",
        }}
      >
        {videos.map((video) => (
          <video
            src={video}
            width={"100%"}
            height={"auto"}
            onClick={() =>
              dispatch(
                openModal({
                  rect: ref.current!.getBoundingClientRect(),
                  modalData: {
                    type: "carousel",
                    items: videos.map((video) => ({
                      type: "video",
                      src: video,
                    })),
                  },
                })
              )
            }
          />
        ))}
      </div>
      <VerticalGrid type="item">
        <Grid
          container
          direction={"row"}
          alignContent={"center"}
          bgcolor={"radiesseText.main"}
          height={"100%"}
        >
          <Grid item xs={2} onClick={previous}>
            <Grid container justifyContent={"center"}>
              <LeftIcon height={"1rem"} />
            </Grid>
          </Grid>
          <Grid item xs={8}>
            <Grid
              container
              direction={"row"}
              width={"100%"}
              justifyContent={"center"}
              alignContent={"center"}
              height={"100%"}
              gap={1}
            >
              {videos.map((_, i) => (
                <Grid item>
                  <Box
                    width={"0.5rem"}
                    height={"0.5rem"}
                    bgcolor={"white"}
                    borderRadius={"1rem"}
                    sx={{
                      opacity: selected === i ? 1 : 0.5,
                    }}
                  ></Box>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={2} onClick={next}>
            <Grid container justifyContent={"center"}>
              <RightIcon height={"1rem"} />
            </Grid>
          </Grid>
        </Grid>
      </VerticalGrid>
    </VerticalGrid>
  );
}
