/* eslint-disable jsx-a11y/alt-text */
import { Box, Grid, Typography } from "@mui/material";
import {
  InternalPage,
  ZIndexes,
} from "../../components/InternalPage/InternalPage";
import {
  useIsRouteVisible,
  useRouter,
} from "../../components/MainRouter/MainRouter";
import { ImageKeys } from "../../utils/assets";
import { Routes } from "../../utils/routes";
import { TextCard } from "../../components/Card/TextCard";
import { PDFVisualizer } from "../../components/Card/PDFVisualizer";
import { BocotureLogo } from "../../components/Logos/BocotureLogo";
import { ShowMore } from "../../components/Atoms/ShowMore";
import { VideoKeys } from "../../utils/assets";
import { useAsset, useSingleAsset } from "../../components/AsssetsLoader";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { BackgroundIntro, Header } from "./BioRepeelPage";
import { VerticalGrid } from "../../components/HorizontalGrid/HorizontalGrid";
import { setIntroViewed } from "../../redux/pages.reducer";
import { useEffect, useState } from "react";
import { bocotureEnabled } from "../../constants";

export function BocoturePage() {
  const isVisible = useIsRouteVisible(Routes.bocoture);
  const { changeRoute } = useRouter();

  const [bgImage] = useAsset<ImageKeys>(["bocoture_bg"]);

  if (!isVisible) return <></>;

  return (
    <InternalPage
      color="bocotureBlue"
      logoFill="black"
      backgroundColor="white"
      goUpper={() =>
        changeRoute(Routes.inostriprodotti, { showPrevious: false })
      }
      goBack={() => changeRoute(Routes.radiesse, { showPrevious: false })}
      goNext={() => changeRoute(Routes.ultherapy, { showPrevious: false })}
      sectionHeader={
        <Header id="bocoture" color="bocotureBlue" showEverywhere>
          <Typography
            variant="h3"
            textAlign="center"
            sx={{ color: "bocotureBlue.main" }}
          >
            <BocotureLogo width={"20rem"} />
          </Typography>
        </Header>
      }
    >
      <Core />
      <BackgroundBocoture />
      <BottomBarIntro />
      <Footer />

      <BackgroundIntro id="bocoture" type="img" src={bgImage} />
    </InternalPage>
  );
}

function BottomBarIntro() {
  const isViewed = useAppSelector((e) =>
    e.pages.intro_viewed.some((e) => e === "bocoture")
  );
  const dispatch = useAppDispatch();
  return isViewed ? (
    <></>
  ) : (
    <Box
      position={"absolute"}
      bottom={0}
      zIndex={ZIndexes.everywhere}
      width={"100%"}
    >
      <Grid
        container
        direction={"column"}
        pb={6}
        gap={2}
        style={{ color: "white" }}
      >
        <Grid container direction="row" justifyContent={"center"}>
          <Grid item xs={4}>
            <Typography textAlign={"center"}>
              BOCOUTURE® è la tossina doppiamente purificata<sup>1*</sup>
              <br /> priva di proteine complessanti,<sup>2,3</sup>
              <br /> per ridurre il rischio di sviluppo di anticorpi
              neutralizzanti.<sup>1,4</sup>
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction="row">
          <Grid item xs></Grid>
          <Grid item xs={4}>
            <Typography fontSize={"0.8rem"} textAlign={"center"}>
              *IncolbotulinumtoxinA è purificata dalle proteine complessanti
              attraverso vari passaggi cromatografici per limitare la possibile
              denaturazione, degradazione e perdita di attività biologica.
            </Typography>
          </Grid>
          <Grid item xs></Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent={"center"}
          alignContent={"center"}
        >
          <Grid item>
            {bocotureEnabled && (
              <ShowMore
                color={"bocotureBlue"}
                onClick={() => dispatch(setIntroViewed("bocoture"))}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

function BackgroundBocoture() {
  const isViewed = useAppSelector((state) =>
    state.pages.intro_viewed.some((e) => e === "bocoture")
  );
  return isViewed ? (
    <></>
  ) : (
    <Box
      position="fixed"
      bottom={0}
      left={0}
      width={"100%"}
      zIndex={ZIndexes.intro + 1}
      sx={{
        transform: "matrix(-1, 0, 0, -1, 0, 0)",
        opacity: 0.75,
        background:
          "transparent linear-gradient(180deg, #000000 0%, #000000 35%, #FFFFFF00 100%) 0% 0% no-repeat padding-box;",
      }}
      height={"50vh"}
    ></Box>
  );
}

function Footer() {
  return (
    <Box
      position="fixed"
      bottom={0}
      zIndex={ZIndexes.dialog}
      left={0}
      width={"100%"}
    >
      <Grid
        container
        width={"100%"}
        paddingBottom={1}
        px={8}
        justifyContent={"space-between"}
        alignContent={"center"}
        textAlign={"right"}
        direction={"row"}
        color={"#8A8A8A"}
        className="special"
        style={{ lineHeight: "0.5rem" }}
      >
        <Grid item xs={4} alignSelf={"flex-start"}>
          <Typography textAlign={"left"} variant="caption" component="p">
            Classe C<br /> Regime di fornitura USPL. Medicinali soggetti a
            prescrizione medica limitativa.
            <br /> Uso riservato agli specialisti durante la visita
            ambulatoriale, ovvero in studi medici attrezzati.
            <br /> Vietata la vendita al pubblico Prezzo: € 385,43, confezione 1
            flaconcino da 100U, AIC n. 040016053
            <br /> Prezzo: € 202,35, confezione 1 flaconcino da 50U, AIC n.
            040016014
            <br /> Codice deposito aziendale: <strong>ESTBO077MRZ</strong>
            <br /> Pubblicità rivolta ai medici depositata in AIFA il{" "}
            <strong>20.09.2023</strong>.
            <br /> VIETATA LA DISTRIBUZIONE/ESPOSIZIONE AL PUBBLICO.
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography textAlign={"right"} variant="caption">
            Riferimenti bibliografici: 1. Comella C. et al. Journal of the
            Neurological Sciences 2011
            <br />
            2. Frevert J. Drugs R D., Pharmaceutical, Biological, and Clinical
            Properties of Botulinum Neurotoxin Type A Products 2015;15:1-9
            <br />
            3. Frevert J, Cosmet Med, Di"erence between botolinum products.
            2013; 156:156-159
            <br />
            4. Grein S, et al. The Botulinum J, Stability of botulinum
            neurotoxin type A devoid of complexing protein, 2011; 2:49-57
            <br />
            5. RCP BOCOUTURE®, Maggio 2020
            <br />
            6. Prager W, et al. Clin Cosmet Investig Dermatol. <br />
            Botulinum toxin type A treatment to the upper face: a retrospective
            analysis of daily practice 2012; 5:53-58
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

function Core() {
  const isViewed = useAppSelector((e) =>
    e.pages.intro_viewed.some((e) => e === "bocoture")
  );
  const [show, setShow] = useState(isViewed);

  useEffect(() => {
    if (!show) {
      const timeout = setTimeout(() => setShow(true), 500);
      return () => clearTimeout(timeout);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <VerticalGrid
      type="container"
      rows={["auto 9rem"]}
      sx={{ px: 8, visibility: show ? "visible" : "hidden", pt: 8 }}
    >
      <VerticalGrid type="item">
        <VerticalGrid type="container" rows={["auto", "1rem", "48%"]}>
          <BocotureTop />
          <VerticalGrid type="item"></VerticalGrid>
          <VerticalGrid type="item">
            <BocotureBottom />
          </VerticalGrid>
        </VerticalGrid>
      </VerticalGrid>
      <VerticalGrid type="item"></VerticalGrid>
    </VerticalGrid>
  );
}

function BocotureTop() {
  const bocoture_purezza = useSingleAsset<VideoKeys>("bocoture_purezza");
  const [pack_100, pack_50] = useAsset<ImageKeys>([
    "bocoture_pack_100",
    "bocoture_pack_50",
  ]);
  return (
    <Grid container direction="row" gap={2}>
      <Grid item xs={3}>
        <TextCard color="bocotureBlue" title="Tecnologia">
          <Grid container width={"100%"} justifyContent={"center"}>
            <video
              crossOrigin="anonymous"
              src={bocoture_purezza}
              autoPlay
              muted
              loop
              style={{ width: "40%", height: "auto", objectFit: "cover" }}
            />
          </Grid>

          <Typography variant="body2" pt={1}>
            È prodotta in <strong>Germania</strong> in uno stabilimento dedicato
            con una tecnologia di purificazione sofisticata la{" "}
            <strong>XTRACT Technology™</strong> che isola la parte
            terapeuticamente attiva<sup>1,2,4</sup> della proteina (100 Kda),
            depurandala dalle proteine complessanti<sup>1,2,4</sup> che possono
            innescare una risposta immunitaria nei pazienti<sup>1</sup>
            rendendoli resistenti al trattamento successivo<sup>2</sup>
          </Typography>
        </TextCard>
      </Grid>
      <Grid item xs={3}>
        <TextCard
          color="bocotureBlue"
          title="Personalizzazione del trattamento"
        >
          <Grid container direction={"column"} height={"100%"}>
            <Grid item>
              <Typography variant="body2">
                <strong>BOCOUTURE®</strong>
                <br /> è disponibile in formato da
                <br />
                <Box
                  component="span"
                  sx={{ fontSize: "2.5rem", color: "bocotureBlue.main" }}
                >
                  <strong>50</strong>
                </Box>
                <Box component="span" sx={{ color: "bocotureBlue.main" }}>
                  <strong>U</strong>
                </Box>{" "}
                e{" "}
                <Box
                  component="span"
                  sx={{ fontSize: "2.5rem", color: "bocotureBlue.main" }}
                >
                  <strong>100</strong>
                </Box>
                <Box component="span" sx={{ color: "bocotureBlue.main" }}>
                  <strong>U</strong>
                </Box>
              </Typography>
            </Grid>
            <Grid item xs>
              <Grid container direction={"row"} height={"100%"}>
                <Grid item xs={6}>
                  <img
                    src={pack_50}
                    style={{
                      height: "auto",
                      objectFit: "cover",
                      width: "100%",
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <img
                    src={pack_100}
                    style={{
                      height: "auto",
                      objectFit: "cover",
                      width: "100%",
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </TextCard>
      </Grid>
      <Grid item xs={3} pr={2}>
        <Grid
          container
          direction={"column"}
          height={"100%"}
          justifyContent={"space-between"}
        >
          <Grid item xs>
            <TextCard color="bocotureBlue" title="Stabilità" titlePb={1}>
              <Typography variant="body2">
                La fiala di <strong>BOCOUTURE®</strong> integra può essere
                conservata a <strong>temperatura ambiente</strong> per 3 anni
                senza refrigerazione<sup>2</sup>. Condizioni di conservazione:
                fino a 25 gradi centigradi<sup>2</sup>
              </Typography>
            </TextCard>
          </Grid>
          <Grid item xs>
            <TextCard
              color="bocotureBlue"
              title="Approvata per l'intero terzo superiore"
            >
              <Typography variant="body2">
                <strong>BOCOUTURE®</strong> è approvata in Europa per il
                trattamento di glabella, rughe frontali e rughe perioculari
                <sup>2</sup>, sia come singole indicazioni che come trattamenti
                combinati
              </Typography>
            </TextCard>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs>
        <TextCard color="bocotureBlue" title="Statistiche" titlePb={1}>
          <Typography variant="body2">
            <strong>BOCOUTURE®</strong> ha raggiunto più del
          </Typography>
          <Typography variant="h1" sx={{ color: "bocotureBlue.main" }}>
            <strong>96%</strong>
          </Typography>
          <Typography variant="body2">
            di soddisfazione nei pazienti<sup>6</sup>
          </Typography>
        </TextCard>
      </Grid>
    </Grid>
  );
}

function BocotureBottom() {
  return (
    <Grid
      container
      direction="row"
      height={"100%"}
      width={"100%"}
      justifyContent={"space-between"}
    >
      <Grid item xs={1.8}>
        <TextCard color="bocotureBlue" title="RCP">
          <PDFVisualizer
            image="rcp"
            pages={4}
            color="bocotureBlue"
            text="Visualizza la scheda"
          />
        </TextCard>
      </Grid>
      <Grid item xs={1.8}>
        <TextCard color="bocotureBlue" title="Dosaggio">
          <PDFVisualizer
            image="dosaggio"
            pages={1}
            color="bocotureBlue"
            text="Visualizza la scheda"
          />
        </TextCard>
      </Grid>
      <Grid item xs={2}>
        <TextCard
          overflow
          color="bocotureBlue"
          title={
            <>
              Ricostituzione e diluizione<sup>2</sup>
            </>
          }
        >
          <PDFVisualizer
            image="bocoture_ricostruzione"
            pages={1}
            color="bocotureBlue"
            text="Visualizza le istruzioni"
          />
        </TextCard>
      </Grid>
      <Grid item xs={1.8}>
        <TextCard color="bocotureBlue" title="Risultati">
          <PDFVisualizer
            image="bocoture_risultati"
            pages={1}
            color="bocotureBlue"
            text="Visualizza"
          />
        </TextCard>
      </Grid>
      <Grid item xs={1.8}>
        <TextCard color="bocotureBlue" title="Science brief">
          <PDFVisualizer
            image="bocoture_science_brief"
            pages={20}
            color="bocotureBlue"
            text="Visualizza"
            byWidth
          />
        </TextCard>
      </Grid>
    </Grid>
  );
}
