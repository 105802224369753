/* eslint-disable jsx-a11y/alt-text */
import { useCallback, useEffect, useMemo } from "react";
import {
  convertClipPathToCSS,
  getCenterOfClipPath,
  getInitialClipPath,
  performHitTest,
  validateClipPath,
} from "../../../utils/functions";
import { ImageKeys } from "../../../utils/assets";
import { layersData } from "../../../constants/viso.constants";
import {
  setLayerSelected,
  setSelectedPoint,
  setStatus,
  showLayerDetail,
  toggleRotation,
  unselectePoint,
  useImageTransform,
} from "../../../redux/viso.reducer";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { Box, Grid, Typography } from "@mui/material";
import { PulsingDOT } from "../../../components/Atoms/PulsingDOT";
import {
  TransformComponent,
  TransformWrapper,
  useControls,
} from "react-zoom-pan-pinch";
import { RotateIcon } from "../../../components/Icons/Rotate";
import { useAsset } from "../../../components/AsssetsLoader";
import React from "react";
import { visokeys } from "../VisoPage";
import { ZIndexes } from "../../../components/InternalPage/InternalPage";
import {
  maxZoomScale,
  focusInAnimation,
  transitionEnabled,
} from "../../../constants";
import { WebmVideo } from "../UltherapyPage";

export type Layer = {
  id: string;
  image: HTMLImageElement;
};

export interface VisoImageProps {
  animationDuration?: number;
  width?: React.CSSProperties["width"];
  height?: React.CSSProperties["height"];
}

const _VisoImage = ({ animationDuration, width, height }: VisoImageProps) => {
  const duration = useMemo(() => animationDuration ?? 1, [animationDuration]);
  const transform = useImageTransform();

  return (
    <div
      style={{
        position: "relative",
        width,
        height,
      }}
    >
      <TransformWrapper
        panning={{ disabled: true }}
        wheel={{ disabled: true }}
        pinch={{ disabled: true }}
        doubleClick={{ disabled: true }}
        maxScale={maxZoomScale}
      >
        <TransformComponent
          wrapperStyle={{
            width,
            height,
            position: "absolute",
            overflow: "visible",
          }}
          contentStyle={{
            width,
            height,
            position: "absolute",
          }}
        >
          <ZoomControl />
          <InternalImage
            width={width}
            height={height}
            duration={duration}
            transform={transform}
          />
          <AreeLayer
            width={width}
            height={height}
            duration={duration}
            transform={transform}
          />
          <ClipPathImage
            width={width}
            height={height}
            duration={duration}
            transform={transform}
          />
          <ImageCanvas
            width={width}
            height={height}
            duration={duration}
            transform={transform}
          />
          <MuscleVideo />
          <Rotation />
        </TransformComponent>
      </TransformWrapper>
    </div>
  );
};

function ZoomControl() {
  const isSelected = useAppSelector(
    (state) => state.viso.selectedPoint !== undefined
  );
  const { resetTransform } = useControls();
  useEffect(() => {
    if (!isSelected) resetTransform();
  }, [isSelected, resetTransform]);
  return <></>;
}

function MuscleVideo() {
  const isMuscles = useAppSelector((state) => state.viso.status === "muscles");
  const dispatch = useAppDispatch();
  return isMuscles ? (
    <Box
      position={"absolute"}
      bottom={"0"}
      width={"100%"}
      height={"100%"}
      zIndex={ZIndexes.dialog}
    >
      <WebmVideo
        src={"viso_muscles"}
        width={"100%"}
        height={"100%"}
        autoPlay={true}
        onEnded={() => {
          dispatch(setStatus("fullscreen"));
        }}
      />
    </Box>
  ) : (
    <></>
  );
}

export const VisoImage = React.memo(_VisoImage, (prev, next) => {
  return true;
});

const InternalImage = ({ width, height, duration, transform }: Props) => {
  const viso = useAsset(visokeys);
  const frame = useAppSelector((state) => state.viso.currentFrame);

  return (
    <Box width={width} height={height} position={"relative"}>
      {viso.map((e, i) => (
        <VisibleImage
          key={i}
          width={width}
          duration={duration}
          image={e}
          transform={transform}
          visible={i === frame}
          height={height}
        />
      ))}
    </Box>
  );
};
const _VisibleImage = ({
  width,
  height,
  duration,
  transform,
  image,
  visible,
}: Props & { visible: boolean; image: string }) => {
  return (
    <img
      src={image}
      width={width}
      height={height}
      style={{
        left: 0,
        top: 0,
        transform,
        transition: !transitionEnabled
          ? undefined
          : `transform ${duration}s ease-out, transformOrigin ${duration}s ease-out`,
        position: "absolute",
        userSelect: "none",
        opacity: visible ? 1 : 0,
      }}
      draggable={false}
    />
  );
};

const VisibleImage = React.memo(_VisibleImage);

const layersIDS = Object.keys(layersData);

function Rotation() {
  const { isDetail, rotationEnable } = useAppSelector((state) => ({
    isDetail: state.viso.status === "detail",
    rotationEnable: state.viso.rotationEnable,
  }));
  const dispatch = useAppDispatch();

  const { resetTransform } = useControls();

  return (
    <div
      style={{
        position: "absolute",
        bottom: "10%",
        left: "11%",
        opacity: isDetail ? 1 : 0,
      }}
      onClick={() => {
        resetTransform();
        dispatch(toggleRotation());
      }}
    >
      <RotateIcon height={"5rem"} fill={rotationEnable ? "green" : "#E8E7E7"} />
    </div>
  );
}

function useLayers(currentFrame: number = 21) {
  const layers = useMemo(
    () =>
      Object.entries(layersData)
        .filter(([k, v]) => v.layer !== undefined)
        .map(([k, v]) => ({ id: k, image: v.layer![currentFrame] })),
    [currentFrame]
  );
  const aree = useAsset(layers.map((e) => e.image));
  return useMemo(
    () =>
      aree
        .filter((e) => e !== null)
        .map((image, i) => ({ image, id: layers[i].id })),
    [aree, layers]
  );
}

interface Props {
  width?: React.CSSProperties["width"];
  height?: React.CSSProperties["height"];
  duration: number;
  transform: string;
}

function AreeLayer({ width, height, duration, transform }: Props) {
  const { layerSelected, showLayer, currentFrame } = useAppSelector(
    (state) => ({
      layerSelected: state.viso.layerSelected,
      currentFrame: state.viso.currentFrame,
      showLayer: state.viso.status === "fullscreen",
    })
  );
  const layers = useLayers(currentFrame);

  const transition = useMemo(() => {
    if (showLayer) {
      return `transform ${duration}s ease-out, transformOrigin ${duration}s ease-out`;
    } else {
      return `transform ${duration}s ease-out, transformOrigin ${duration}s ease-out, opacity ${duration}s ease-out`;
    }
  }, [duration, showLayer]);

  return (
    <>
      {layers
        .filter((e) => e.image !== undefined)
        .map(({ image, id }) => (
          <img
            key={id}
            src={image}
            width={width}
            height={height}
            style={{
              transform,
              transition,
              //   transitionDelay,
              marginLeft: "-3px",
              opacity: id === layerSelected ? 0.5 : 0,
              position: "absolute",
              userSelect: "none",
            }}
            draggable={false}
          ></img>
        ))}
    </>
  );
}

function ClipPathImage({ width, height, duration, transform }: Props) {
  const clipPathImage = useAppSelector((state) => state.viso.clipPathImage);

  return clipPathImage !== undefined ? (
    <img
      key={clipPathImage.src}
      src={clipPathImage.src}
      width={width}
      height={height}
      style={{
        transform,
        transition: !transitionEnabled
          ? undefined
          : `clip-path ${duration}s ease-out, transform ${duration}s ease-out, transformOrigin ${duration}s ease-out`,
        // transitionDelay,
        clipPath: convertClipPathToCSS(clipPathImage.clipPath, 1),
        position: "absolute",
        userSelect: "none",
      }}
      draggable={false}
    ></img>
  ) : (
    <></>
  );
}

function ImageCanvas({ width, height, duration, transform }: Props) {
  const layers = useLayers();
  const { rotationEnable, status } = useAppSelector((state) => ({
    rotationEnable: state.viso.rotationEnable,
    status: state.viso.status,
  }));
  const { resetTransform } = useControls();
  const dispatch = useAppDispatch();

  const svgOnClick = useCallback(
    (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
      const canClick =
        (status === "fullscreen" || status === "detail") && !rotationEnable;
      if (
        e.target instanceof SVGSVGElement &&
        layers !== undefined &&
        canClick
      ) {
        // Get the coordinates relative to the clicked element
        const rect = e.target.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;

        const res = performHitTest(
          x,
          y,
          rect.width,
          rect.height,
          layers.map((e) => {
            let image = new Image();
            image.src = e.image;
            return image;
          })
        );
        if (res !== undefined) {
          const selectedLayer = layers[res];
          if (
            status === "fullscreen" ||
            (status === "detail" && selectedLayer !== undefined)
          ) {
            dispatch(setLayerSelected(selectedLayer?.id as ImageKeys));
          }
          if (status === "detail") dispatch(showLayerDetail());
        }
      }
      dispatch(unselectePoint());
      resetTransform();
    },
    [dispatch, layers, resetTransform, status, rotationEnable]
  );
  return (
    <svg
      width={width}
      height={height}
      style={{
        position: "absolute",
        transform,
        transition: !transitionEnabled
          ? undefined
          : `transform ${duration}s ease-out, transformOrigin ${duration}s ease-out`,
      }}
      onClick={svgOnClick}
    >
      <PulsingPoints />
      <SelectionBox />
    </svg>
  );
}

function PulsingPoints() {
  const dispatch = useAppDispatch();
  const { zoomToElement } = useControls();

  const { selectionPoints, selectedPoint, currentFrame } = useAppSelector(
    (state) => ({
      selectionPoints: state.viso.layer?.selectionPoints,
      selectedPoint: state.viso.selectedPoint,
      currentFrame: state.viso.currentFrame,
    })
  );

  const point_images = useAsset<ImageKeys>(
    selectionPoints
      ?.filter((e) => e.point[currentFrame] !== undefined)
      .map((e) => e.point[currentFrame].image) ?? []
  );

  const size = 3;

  return (
    <>
      {selectionPoints
        ?.filter((e) => e.point[currentFrame] !== undefined)
        .map((e, i) => {
          const current = e.point[currentFrame];
          const x = validateClipPath(e.point[currentFrame].clipPath);
          const center = getCenterOfClipPath(x);
          return (
            <>
              {current?.position && center && (
                <g
                  id={e.point[currentFrame].image + "_offset"}
                  style={{
                    transform: `translate(${center.x}%,${center.y}%)`,
                    visibility: "hidden",
                  }}
                >
                  <circle id="radar" cx="0" cy="0" r={size * 50} />
                </g>
              )}
              <PulsingDOT
                key={i}
                id={e.point[currentFrame].image}
                point={current?.position ?? center}
                pulse={false}
                size={size}
                active={e === selectedPoint}
                visible={e !== selectedPoint}
                fill={current?.color ?? "white"}
                onClick={() => {
                  const initialPoints = getInitialClipPath(x);
                  const src = point_images[i];
                  zoomToElement(
                    e.point[currentFrame].image +
                      (current.position !== undefined ? "_offset" : "")
                  );
                  dispatch(
                    setSelectedPoint({
                      clipPath: { clipPath: initialPoints, src },
                      selectedPoint: e,
                    })
                  );

                  setTimeout(() => {
                    dispatch(
                      setSelectedPoint({
                        clipPath: { clipPath: x, src },
                        selectedPoint: e,
                      })
                    );
                  }, 500);
                }}
              />
            </>
          );
        })}
    </>
  );
}

function SelectionBox() {
  const values = useMemo(() => Object.values(layersData), []);
  const dispatch = useAppDispatch();

  const { layerSelected, isFullScreen } = useAppSelector((state) => ({
    layerSelected:
      state.viso.layerSelected !== undefined
        ? layersData[state.viso.layerSelected]
        : undefined,
    isFullScreen: state.viso.status === "fullscreen",
  }));
  if (!isFullScreen) return <></>;
  return (
    <>
      {values
        .filter((e) => e.textBox !== undefined)
        .map((e, i) => (
          <foreignObject
            key={i}
            x={`${e.textBox?.x}%`}
            y={`${e.textBox?.y}%`}
            width="200"
            height="40"
          >
            <Grid
              container
              justifyContent={"center"}
              alignItems={"center"}
              height={"100%"}
              sx={{
                ...focusInAnimation,
                animationDelay: `${i * 0.5}s`,
                backgroundColor:
                  e.name === layerSelected?.name
                    ? "debianRed.main"
                    : "rgba(216,216,216,0.5)",
              }}
              onClick={() =>
                dispatch(setLayerSelected(layersIDS[i] as ImageKeys))
              }
            >
              <Typography
                style={{
                  color: e.name === layerSelected?.name ? "white" : "black",
                }}
              >
                {e.name
                  ?.split(" ")
                  .map((e, i) =>
                    i === 0 ? <strong key={i}>{e}</strong> : <> {e}</>
                  )}
              </Typography>
            </Grid>
          </foreignObject>
        ))}
    </>
  );
}
