/* eslint-disable jsx-a11y/alt-text */
import { Typography, Grid } from "@mui/material";
import { HorizontalGrid } from "../../components/HorizontalGrid/HorizontalGrid";
import { useAsset } from "../../components/AsssetsLoader";
import { ImageKeys } from "../../utils/assets";
import {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useWindowSize } from "@uidotdev/usehooks";
import { preventDefault } from "../../components/Modal";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setIndex } from "../../redux/belotero.reducer";
import { TopDescription } from "./BioRepeelPage";
import { focusInAnimation } from "../../constants";

const columnPack = 0.135;
const rowPack = 0.3;
const rowTitle = 0.05;

export const beloteroProdottiDelay = 0;
const animations: Array<CSSProperties> = [
  {
    animation: "rightToLeft0 1s ease-out",
    animationFillMode: "both",
  },
  {
    animation: "rightToLeft1 1.5s ease-out",
    animationFillMode: "both",
  },
  {
    animation: "rightToLeft2 2s ease-out",
    animationFillMode: "both",
  },
  {
    animation: "rightToLeft3 2.5s ease-out",
    animationFillMode: "both",
  },
  {
    animation: "rightToLeft4 3s ease-out",
    animationFillMode: "both",
  },
  {
    animation: "rightToLeft5 3.5s ease-out",
    animationFillMode: "both",
  },
];

export function useHandleIntro(id: string, when: "parallel" | "after") {
  const isViewed = useAppSelector((state) =>
    state.pages.intro_viewed.some((e) => e === id)
  );

  const [show, setShow] = useState(false);
  useEffect(() => {
    setShow(isViewed || when === "parallel");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [when]);

  useEffect(() => {
    if (isViewed && !show) {
      const timeout = setTimeout(() => setShow(true), 2000);
      return () => clearTimeout(timeout);
    }
  }, [isViewed, show]);
  return show;
}

export function BeloteroIntroProdotti() {
  const [
    belotero_pack_revive,
    belotero_pack_softp,
    belotero_pack_soft,
    belotero_pack_balancep,
    belotero_pack_balance,
    belotero_pack_intensep,
    belotero_pack_intense,
    belotero_pack_volumep,
    belotero_pack_volume,
    belotero_pack_lipss,
    belotero_pack_lipsc,
  ] = useAsset<ImageKeys>([
    "belotero_pack_revive",
    "belotero_pack_softp",
    "belotero_pack_soft",
    "belotero_pack_balancep",
    "belotero_pack_balance",
    "belotero_pack_intensep",
    "belotero_pack_intense",
    "belotero_pack_volumep",
    "belotero_pack_volume",
    "belotero_pack_lipss",
    "belotero_pack_lipsc",
  ]);

  const { width, height } = useWindowSize();

  const { selected } = useAppSelector((state) => ({
    selected: state.belotero.index,
  }));

  const show = useHandleIntro("belotero", "after");

  const dispatch = useAppDispatch();

  const dotSize = useMemo(
    () => (height !== null ? height * 0.014 : undefined),
    [height]
  );
  const padding = useMemo(
    () =>
      width !== null ? (width - (width - 1) * columnPack * 6) / 4 : undefined,
    [width]
  );

  const selectProduct = useCallback(
    (index: number | null) => {
      dispatch(setIndex(index));
    },
    [dispatch]
  );

  return (
    <>
      {padding && dotSize && height && width && show ? (
        <>
          <Grid item>
            <Grid
              container
              direction={"row"}
              justifyContent={"center"}
              sx={{
                ...focusInAnimation,
                animationDelay: `${beloteroProdottiDelay}s`,
              }}
            >
              <TopDescription color="belotero">
                La gamma BELOTERO® di filler all'acido ialuronico è realizzata
                su misura per adattarsi a diverse aree del viso e profondità
              </TopDescription>
            </Grid>
          </Grid>
          <Grid item>
            <Grid
              container
              width={"100%"}
              height={"100%"}
              direction={"column"}
              rowGap={2}
              justifyContent={"center"}
              alignContent={"center"}
              color={"black"}
            >
              <Grid item>
                <Typography
                  textAlign={"center"}
                  variant="body1"
                  sx={{
                    ...focusInAnimation,
                    animationDelay: `${beloteroProdottiDelay}s`,
                  }}
                  style={{ color: "black" }}
                >
                  Seleziona il <strong>prodotto</strong> della gamma che vuoi
                  approfondire
                </Typography>
              </Grid>
              <Grid item marginLeft={`${-((width * columnPack) / 2)}px`}>
                <HorizontalGrid
                  type="container"
                  gap={1}
                  rowSize={height * rowPack}
                  columnSize={width * columnPack}
                  columns={6}
                >
                  <HorizontalGrid
                    type="item"
                    sx={{
                      opacity: selected == null || selected === 0 ? 1 : 0.2,
                    }}
                  >
                    <Grid
                      container
                      direction={"row"}
                      justifyContent={"flex-end"}
                      height={"100%"}
                    >
                      <Grid item xs={6} height={"100%"}>
                        <Grid
                          container
                          direction={"row"}
                          height={"100%"}
                          justifyContent={"space-around"}
                        >
                          <img
                            src={belotero_pack_revive}
                            style={{
                              ...animations[0],
                              height: "100%",
                              width: "auto",
                              objectFit: "cover",
                            }}
                            onClick={(e) => {
                              selectProduct(0);
                              preventDefault(e);
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </HorizontalGrid>
                  <HorizontalGrid
                    type="item"
                    sx={{
                      opacity: selected == null || selected === 1 ? 1 : 0.2,
                    }}
                  >
                    <Grid
                      container
                      direction={"row"}
                      justifyContent={"space-around"}
                      height={"100%"}
                      style={{ ...animations[1] }}
                    >
                      <Grid item height={"100%"}>
                        <img
                          src={belotero_pack_softp}
                          style={{
                            height: "100%",
                            width: "auto",
                            objectFit: "cover",
                          }}
                          onClick={(e) => {
                            selectProduct(1);
                            preventDefault(e);
                          }}
                        />
                      </Grid>
                      <Grid item height={"100%"}>
                        <img
                          src={belotero_pack_soft}
                          style={{
                            height: "100%",
                            width: "auto",
                            objectFit: "cover",
                          }}
                          onClick={(e) => {
                            selectProduct(1);
                            preventDefault(e);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </HorizontalGrid>
                  <HorizontalGrid
                    type="item"
                    sx={{
                      opacity: selected == null || selected === 2 ? 1 : 0.2,
                    }}
                  >
                    <Grid
                      container
                      direction={"row"}
                      justifyContent={"space-around"}
                      height={"100%"}
                      style={{ ...animations[2] }}
                    >
                      <Grid item height={"100%"}>
                        <img
                          src={belotero_pack_balancep}
                          style={{
                            height: "100%",
                            width: "auto",
                            objectFit: "cover",
                          }}
                          onClick={(e) => {
                            selectProduct(2);
                            preventDefault(e);
                          }}
                        />
                      </Grid>
                      <Grid item height={"100%"}>
                        <img
                          src={belotero_pack_balance}
                          style={{
                            height: "100%",
                            width: "auto",
                            objectFit: "cover",
                          }}
                          onClick={(e) => {
                            selectProduct(2);
                            preventDefault(e);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </HorizontalGrid>
                  <HorizontalGrid
                    type="item"
                    sx={{
                      opacity: selected == null || selected === 3 ? 1 : 0.2,
                    }}
                  >
                    <Grid
                      container
                      direction={"row"}
                      justifyContent={"space-around"}
                      height={"100%"}
                      style={{ ...animations[3] }}
                    >
                      <Grid item height={"100%"}>
                        <img
                          src={belotero_pack_intensep}
                          style={{
                            height: "100%",
                            width: "auto",
                            objectFit: "cover",
                          }}
                          onClick={(e) => {
                            selectProduct(3);
                            preventDefault(e);
                          }}
                        />
                      </Grid>
                      <Grid item height={"100%"}>
                        <img
                          src={belotero_pack_intense}
                          style={{
                            height: "100%",
                            width: "auto",
                            objectFit: "cover",
                          }}
                          onClick={(e) => {
                            selectProduct(3);
                            preventDefault(e);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </HorizontalGrid>
                  <HorizontalGrid
                    type="item"
                    sx={{
                      opacity: selected == null || selected === 4 ? 1 : 0.2,
                    }}
                  >
                    <Grid
                      container
                      direction={"row"}
                      justifyContent={"space-around"}
                      height={"100%"}
                      style={{ ...animations[4] }}
                    >
                      <Grid item height={"100%"}>
                        <img
                          src={belotero_pack_volumep}
                          style={{
                            height: "100%",
                            width: "auto",
                            objectFit: "cover",
                          }}
                          onClick={(e) => {
                            selectProduct(4);
                            preventDefault(e);
                          }}
                        />
                      </Grid>
                      <Grid item height={"100%"}>
                        <img
                          src={belotero_pack_volume}
                          style={{
                            height: "100%",
                            width: "auto",
                            objectFit: "cover",
                          }}
                          onClick={(e) => {
                            selectProduct(4);
                            preventDefault(e);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </HorizontalGrid>
                  <HorizontalGrid
                    type="item"
                    sx={{
                      opacity: selected == null || selected === 5 ? 1 : 0.2,
                    }}
                  >
                    <Grid
                      container
                      direction={"row"}
                      justifyContent={"space-around"}
                      height={"100%"}
                      style={{ ...animations[5] }}
                    >
                      <Grid item height={"100%"}>
                        <img
                          src={belotero_pack_lipss}
                          style={{
                            height: "100%",
                            width: "auto",
                            objectFit: "cover",
                          }}
                          onClick={(e) => {
                            selectProduct(5);
                            preventDefault(e);
                          }}
                        />
                      </Grid>
                      <Grid item height={"100%"}>
                        <img
                          src={belotero_pack_lipsc}
                          style={{
                            height: "100%",
                            width: "auto",
                            objectFit: "cover",
                          }}
                          onClick={(e) => {
                            selectProduct(5);
                            preventDefault(e);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </HorizontalGrid>
                </HorizontalGrid>
                <HorizontalGrid
                  type="container"
                  gap={1}
                  rowSize={height * rowTitle}
                  columnSize={width * columnPack}
                  columns={6}
                  sx={{
                    ...focusInAnimation,
                    animationDelay: `${beloteroProdottiDelay}s`,
                  }}
                >
                  <HorizontalGrid type="item">
                    <Grid
                      container
                      direction={"row"}
                      justifyContent={"flex-end"}
                      height={"100%"}
                    >
                      <Grid
                        item
                        xs={6}
                        sx={{
                          backgroundColor: "#F6F6F6",
                        }}
                        onClick={(e) => {
                          selectProduct(0);
                          preventDefault(e);
                        }}
                      >
                        <Grid
                          container
                          height={"100%"}
                          justifyContent={"center"}
                          alignContent={"center"}
                          sx={{ whiteSpace: "break-spaces" }}
                          textAlign={"center"}
                        >
                          <Typography style={{ color: "black" }}>
                            Rivitalizzare e reidratare
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </HorizontalGrid>
                  <HorizontalGrid type="item">
                    <Grid
                      container
                      height={"100%"}
                      justifyContent={"center"}
                      alignContent={"center"}
                      sx={{
                        backgroundColor: "#F6F6F6",
                      }}
                      onClick={(e) => {
                        selectProduct(1);
                        preventDefault(e);
                      }}
                    >
                      <Typography style={{ color: "black" }}>
                        Rifinire e perfezionare
                      </Typography>
                    </Grid>
                  </HorizontalGrid>
                  <HorizontalGrid type="item">
                    <Grid
                      container
                      height={"100%"}
                      justifyContent={"center"}
                      alignContent={"center"}
                      sx={{
                        backgroundColor: "#F6F6F6",
                      }}
                      onClick={(e) => {
                        selectProduct(2);
                        preventDefault(e);
                      }}
                    >
                      <Typography style={{ color: "black" }}>
                        Ricompattare il derma
                      </Typography>
                    </Grid>
                  </HorizontalGrid>
                  <HorizontalGrid type="item">
                    <Grid
                      container
                      height={"100%"}
                      justifyContent={"center"}
                      alignContent={"center"}
                      sx={{
                        backgroundColor: "#F6F6F6",
                      }}
                      onClick={(e) => {
                        selectProduct(3);
                        preventDefault(e);
                      }}
                    >
                      <Typography style={{ color: "black" }}>
                        Riempire e distendere
                      </Typography>
                    </Grid>
                  </HorizontalGrid>
                  <HorizontalGrid type="item">
                    <Grid
                      container
                      height={"100%"}
                      justifyContent={"center"}
                      alignContent={"center"}
                      sx={{
                        backgroundColor: "#F6F6F6",
                      }}
                      onClick={(e) => {
                        selectProduct(4);
                        preventDefault(e);
                      }}
                    >
                      <Typography style={{ color: "black" }}>
                        Ripristinare i volumi
                      </Typography>
                    </Grid>
                  </HorizontalGrid>
                  <HorizontalGrid type="item">
                    <Grid
                      container
                      height={"100%"}
                      justifyContent={"center"}
                      alignContent={"center"}
                      sx={{
                        backgroundColor: "#F6F6F6",
                      }}
                      onClick={(e) => {
                        selectProduct(5);
                        preventDefault(e);
                      }}
                    >
                      <Typography style={{ color: "black" }}>
                        Speciale labbra
                      </Typography>
                    </Grid>
                  </HorizontalGrid>
                </HorizontalGrid>
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
