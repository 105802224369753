import { useMemo } from "react";
import { ModalDOMRect } from "../redux/modal.reducer";

export function useFullScreenSize(rect: ModalDOMRect) {
  const isWiderThanTaller = useMemo(
    () => rect.width > rect.height,
    [rect.width, rect.height]
  );
  return {
    width: isWiderThanTaller ? "50vw" : "auto",
    height: isWiderThanTaller ? "auto" : "80vh",
  };
}
export function useFullScreenModalSize(rect: ModalDOMRect) {
  const isWiderThanTaller = useMemo(
    () => rect.width > rect.height,
    [rect.width, rect.height]
  );
  return {
    width: isWiderThanTaller ? "auto" : "100%",
    height: isWiderThanTaller ? "100%" : "auto",
  };
}
