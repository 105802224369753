import { Grid, Typography } from "@mui/material";
import { TextWithLines } from "../../components/Atoms/TextWithLines";
import {
  HorizontalGrid,
  VerticalGrid,
} from "../../components/HorizontalGrid/HorizontalGrid";
import { RectangleButton } from "../../components/Atoms/RectangleButton";
import { Center } from "./digital_marketing";
import { LensIcon } from "../../components/Icons/LensIcon";
import { useRef } from "react";
import { useAsset } from "../../components/AsssetsLoader";
import { VideoKeys } from "../../utils/assets";
import { useAppDispatch } from "../../redux/hooks";
import { openModal } from "../../redux/modal.reducer";

export function ScientificNetwork() {
  const boxHeight = 3;
  const ref1 = useRef<HTMLDivElement>(null);
  const ref2 = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();
  const [merz_care, innovation_forum] = useAsset<VideoKeys>([
    "elite_merz_care",
    "elite_innovation_forum",
  ]);
  return (
    <VerticalGrid type="container" rows={["7rem", "auto"]}>
      <VerticalGrid type="item"></VerticalGrid>
      <VerticalGrid type="item">
        <Grid
          container
          height={"100%"}
          width={"100%"}
          gap={5}
          direction={"column"}
        >
          <Grid item width={"100%"}>
            <TextWithLines animation="blur 1s ease-out">
              <Typography
                variant="h4"
                textAlign={"center"}
                sx={{
                  animation: "text-focus-in 2s ease-out",
                }}
              >
                <strong>
                  Sviluppiamo la tua <i>reputazione</i>
                  <br /> nella comunità scientifica
                </strong>
              </Typography>
            </TextWithLines>
          </Grid>
          <Grid
            item
            xs
            style={{
              animation: "blur 2s ease-out",
              opacity: 0,
              animationDelay: "0.5s",
              animationFillMode: "forwards",
            }}
          >
            <Grid
              container
              direction={"column"}
              height={"100%"}
              px={10}
              justifyContent={"space-between"}
            >
              <VerticalGrid type="container" rows={[`${boxHeight}rem`, "auto"]}>
                <VerticalGrid type="item">
                  <HorizontalGrid
                    type="container"
                    columnSize={[
                      "2rem",
                      "15rem",
                      "auto",
                      "15rem",
                      "auto",
                      "15rem",
                      "auto",
                    ]}
                    columns={7}
                    scrollable={false}
                    width="100%"
                    sx={{ height: "100%" }}
                  >
                    <HorizontalGrid type="item"></HorizontalGrid>
                    <HorizontalGrid
                      type="item"
                      sx={{
                        border: "1px solid #707070",
                      }}
                    >
                      <Center>
                        <Typography>
                          <i>Merz Care</i>
                        </Typography>
                      </Center>
                    </HorizontalGrid>
                    <HorizontalGrid
                      type="item"
                      sx={{
                        borderBottom: "1px solid #707070",
                      }}
                    ></HorizontalGrid>
                    <HorizontalGrid
                      type="item"
                      sx={{
                        border: "1px solid #707070",
                      }}
                    >
                      <Center>
                        <Typography>
                          <i>Lectures scientifiche</i>
                        </Typography>
                      </Center>
                    </HorizontalGrid>
                    <HorizontalGrid
                      type="item"
                      sx={{
                        borderTop: "1px solid #707070",
                      }}
                    ></HorizontalGrid>
                    <HorizontalGrid
                      type="item"
                      sx={{
                        border: "1px solid #707070",
                      }}
                    >
                      <Center>
                        <Typography>
                          <i>Innovation Forum</i>
                        </Typography>
                      </Center>
                    </HorizontalGrid>
                    <HorizontalGrid type="item"></HorizontalGrid>
                  </HorizontalGrid>
                </VerticalGrid>
                <VerticalGrid type="item">
                  <HorizontalGrid
                    type="container"
                    columnSize={[
                      "2rem",
                      "15rem",
                      "auto",
                      "15rem",
                      "auto",
                      "15rem",
                      "auto",
                    ]}
                    columns={7}
                    scrollable={false}
                    width="100%"
                    sx={{ height: "100%" }}
                  >
                    <HorizontalGrid type="item" />
                    <HorizontalGrid type="item">
                      <Typography py={2}>
                        Gestiamo casi complessi ed eventi avversi tramite un
                        <br />
                        team di esperti specializzato
                      </Typography>
                      <RectangleButton
                        ref={ref1}
                        text="Scopri di più"
                        icon={<LensIcon width={"1.5rem"} />}
                        onClick={() => {
                          dispatch(
                            openModal({
                              modalData: {
                                type: "video",
                                video: merz_care,
                              },
                              rect: ref1.current!.getRect(),
                            })
                          );
                        }}
                      />
                    </HorizontalGrid>
                    <HorizontalGrid type="item"></HorizontalGrid>
                    <HorizontalGrid type="item">
                      <Typography py={2}>
                        Il nostro dipartimento ti supporta nella stesura di
                        <br />
                        documentazione scientifica (slide, abstract,
                        <br />
                        pubblicazioni).
                      </Typography>
                    </HorizontalGrid>
                    <HorizontalGrid type="item"></HorizontalGrid>
                    <HorizontalGrid type="item">
                      <Typography py={2}>
                        <strong>
                          Hai delle idee innovative e vuoi presentare nuove{" "}
                          <br />
                          tecniche o protocolli ad una comunità scientifica di
                          <br />
                          esperti?
                        </strong>
                      </Typography>
                      <Typography pb={2}>
                        Sottoponi un abstract alla EMEA Faculty Merz
                        <br />
                        Aesthetics, se sarai selezionato/a, potrai diventare il
                        <br />
                        prossimo Guest Speaker ad uno degli eventi più ambiti
                        <br />
                        nella comunità scientifica: il MEXS Europeo.
                      </Typography>
                      <RectangleButton
                        ref={ref2}
                        text="Scopri di più"
                        icon={<LensIcon width={"1.5rem"} />}
                        onClick={() => {
                          dispatch(
                            openModal({
                              modalData: {
                                type: "video",
                                video: innovation_forum,
                              },
                              rect: ref2.current!.getRect(),
                            })
                          );
                        }}
                      />
                    </HorizontalGrid>
                    <HorizontalGrid type="item"></HorizontalGrid>
                  </HorizontalGrid>
                </VerticalGrid>
              </VerticalGrid>
            </Grid>
          </Grid>
        </Grid>
      </VerticalGrid>
    </VerticalGrid>
  );
}
