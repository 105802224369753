import * as React from "react";
import { SVGProps } from "react";

const ArrowIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="106.2 41.93 16.7 31.51"
    {...props}
  >
    <path
      d="m121.54 73.44-15.06-15.06a.99.99 0 0 1 0-1.39l15.06-15.06 1.36 1.36-14.39 14.39 14.39 14.39-1.36 1.37zm-13.69-15.08"
      className="st0"
    />
  </svg>
);

export const LeftIcon = ({
  style,
  fill,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <ArrowIcon
    style={{ rotate: "0deg", fill: fill ?? "white", ...style }}
    {...props}
  />
);

export const RightIcon = ({
  style,
  fill,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <ArrowIcon
    style={{ rotate: "-180deg", fill: fill ?? "white", ...style }}
    {...props}
  />
);

export const ArrowDownIcon = ({
  style,
  fill,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <ArrowIcon
    style={{ rotate: "-90deg", fill: fill ?? "white", ...style }}
    {...props}
  />
);

export const ArrowUpIcon = ({
  style,
  fill,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <ArrowIcon
    style={{ rotate: "90deg", fill: fill ?? "white", ...style }}
    {...props}
  />
);
