import { transitionEnabled } from "../../constants";
import { Point } from "../../utils/functions";

interface PulsingDOTProps {
  point: Point | null;
  fill: string;
  size: number;
  pulse: boolean;
  visible: boolean;
  active?: boolean;
  id?: string;
  onClick?: () => void;
}

export function PulsingDOT({
  point,
  fill,
  pulse,
  size,
  visible,
  active,
  id,
  onClick,
}: PulsingDOTProps) {
  if (point) {
    return (
      <>
        <g
          id={id}
          style={{
            transform: `translate(${point.x}%,${point.y}%)`,
            visibility: "hidden",
          }}
        >
          <circle id="radar" cx="0" cy="0" r={size * 50} />
        </g>
        <g
          style={{
            transform: `translate(${point.x}%,${point.y}%)`,
            cursor: "pointer",
            opacity: active ? 1 : 0.5,
          }}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            onClick?.call(undefined);
          }}
        >
          <circle
            cx="0"
            cy="0"
            r={size * 2}
            fill={fill}
            // fillOpacity={0.5}
            style={{
              transform: `scale(${visible ? 1 : 0})`,
              animation: pulse ? "pulse2 1.5s ease-in-out infinite" : undefined,
              transition: !transitionEnabled ? undefined : "transform 1s ease-in-out",
            }}
          ></circle>

          <circle
            id="core"
            cx="0"
            cy="0"
            r={size}
            fill={fill}
            className=""
            style={{
              transform: `scale(${visible ? 1 : 0})`,
              transition: !transitionEnabled ? undefined : "transform 1s ease-in-out",
            }}
          ></circle>
        </g>
      </>
    );
  } else {
    return <></>;
  }
}
