import { MultiLayerImageProps } from "../../components/Experiments/MultiLayerImage/MultiLayerImage";
import { InternalPage } from "../../components/InternalPage/InternalPage";
import { ImageKeys } from "../../utils/assets";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ClipPath, Point } from "../../utils/functions";
import { useRouter } from "../../components/MainRouter/MainRouter";
import { Routes } from "../../utils/routes";
import { VisoSidebar } from "../../components/VisoSidebar/VisoSidebar";
import { Prodotto } from "../../constants/viso.constants";
import { useGestureResponder } from "react-gesture-responder";
import { useWindowSize } from "@uidotdev/usehooks";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  goBack,
  resetViso,
  setFrame,
  setStatus,
  setTransform,
  showLayerDetail,
  unselectePoint,
} from "../../redux/viso.reducer";
import { VisoImage } from "./viso/VisoImage";
import { useAsset } from "../../components/AsssetsLoader";
import { Inizia } from "../../components/Atoms/ShowMore";
import { Title } from "./viso/Viso.title";
import { easeInSine, transitionEnabled } from "../../constants";

export type ObiettivoTrattamento = {
  obiettivo: string;
  prodotto: Prodotto;
  image?: ImageKeys | ImageKeys[];
  ago?: string;
  cannula?: string;
  tecnica: string;
  piano: string;
};

export type SelectionPointDesc = {
  distretto?: string;
  distrettoOption?: string;
  showAreaName?: boolean;
  obiettivi?: Array<ObiettivoTrattamento>;
};

export type SelectionPoint = {
  point: Record<
    number,
    {
      clipPath: ClipPath | string;
      image: ImageKeys;
      position?: Point;
      color?: string;
    }
  >;
  desc: SelectionPointDesc | Array<SelectionPointDesc>;
};
export const visokeys = new Array(41).fill(0).map((_, i) => `viso${i + 1}`);

export type LayerSelection = {
  // explorer?: (props: ExplorerProps) => JSX.Element;
  textBox?: Point;
  name?: string;
  selectionPoints?: Array<SelectionPoint>;
  layer?: Record<number, ImageKeys>;
} & Pick<MultiLayerImageProps, "scale" | "translateX" | "translateY">;

const imgWidthFraction = 0.65;

export function VisoPage() {
  const { currentRoute } = useRouter<DOMRect>();

  const dispatch = useAppDispatch();

  const isVisible = useMemo(() => currentRoute === Routes.viso, [currentRoute]);

  if (!isVisible) return <></>;

  return (
    <InternalPage
      color="debianRed"
      backgroundStyle={{
        background:
          "linear-gradient(90deg, #D2D2D2 0%, #E9E7E7 30%, #F8F4F4 74%, #D2D2D2 100%) ",
      }}
      textColor="black"
      logoFill="black"
      subsection={
        <>
          I nostri <i>prodotti</i>
        </>
      }
      goHome={() => dispatch(resetViso())}
      goUpBuilder={GoUp}
      goBackBuilder={GoBack}
    >
      <Title />
      <Viso />
      <StartButton />
      <VisoSidebar />
    </InternalPage>
  );
}

function StartButton() {
  const dispatch = useAppDispatch();
  const { layerSelected, isFullScreen } = useAppSelector((state) => ({
    layerSelected: state.viso.layerSelected,
    isFullScreen: state.viso.status === "fullscreen",
  }));

  if (!isFullScreen) return <></>;
  return (
    <div
      style={{
        animation: `puff-in-center 1s ${easeInSine}`,
        animationFillMode: "both",
        position: "absolute",
        bottom: "12vh",
        right: "12vw",
        opacity: isFullScreen ? 1 : 0,
        transition: !transitionEnabled ? undefined : "all 1s ease-out",
      }}
    >
      <Inizia
        onClick={() => dispatch(showLayerDetail())}
        active={layerSelected !== undefined}
      />
    </div>
  );
}

function Viso() {
  const viso = useAsset(visokeys);

  const { args } = useRouter<DOMRect>();

  const { bind, size } = useVisoHandler({
    sensitivity: 0.1,
    frameCount: viso.length - 1,
    initialFrame: 0,
    targetFrame: 21,
    initialRect: args as DOMRect,
  });

  return size === null ? (
    <></>
  ) : (
    <div
      {...bind}
      style={{
        position: "absolute",
        bottom: 0,
        left: size.left,
        transition: !transitionEnabled ? undefined : "all 1s ease-out",
      }}
    >
      <VisoImage width={size.width} height={size.height} />
    </div>
  );
}
//Make this better in the sense that diff shuold be at lease the medium of distance between points
function getNearestPoint(point: number): number {
  const points = [0, 21, 41];
  return points.reduce((prev, curr) => {
    return Math.abs(curr - point) < Math.abs(prev - point) ? curr : prev;
  });
}

function useVisoHandler(args: {
  sensitivity: number;
  frameCount: number;
  initialFrame?: number;
  targetFrame?: number;
  initialRect?: DOMRect;
}) {
  const currentFrame = useAppSelector((state) => state.viso.currentFrame);
  const dispatch = useAppDispatch();
  const [lastFrame, setLastFrame] = useState(args.initialFrame ?? 0);

  const dispatchNextFrame = useCallback(
    (frame: number, to: number) => {
      if (frame === to) {
        dispatch(setFrame(frame));
      } else {
        dispatch(setFrame(frame));
        setTimeout(
          () =>
            frame > to
              ? dispatchNextFrame(frame - 1, to)
              : dispatchNextFrame(frame + 1, to),
          10
        );
      }
    },
    [dispatch]
  );

  const onEnd = useCallback(() => {
    const target = getNearestPoint(currentFrame);
    dispatchNextFrame(currentFrame, target);
    setLastFrame(target);
  }, [currentFrame, dispatchNextFrame]);

  useEffect(() => {
    if (args.initialRect !== undefined) {
      const timeout1 = setTimeout(
        () => dispatch(setTransform({ scale: 1, translateY: 0 })),
        100
      );
      const timeout2 = setTimeout(async () => {
        for (let i = 0; i <= 21; i++) {
          dispatch(setFrame(i));
          await new Promise((res) => setTimeout(res, 20));
        }
        dispatch(setStatus("muscles"));
      }, 1100);
      return () => {
        clearTimeout(timeout1);
        clearTimeout(timeout2);
      };
    }
  }, [dispatch, args.initialRect]);

  const { isFullScreen, rotationEnable } = useAppSelector((state) => ({
    isFullScreen: state.viso.status !== "detail",
    rotationEnable: state.viso.rotationEnable,
  }));

  const { bind } = useGestureResponder({
    onStartShouldSet: () => true,
    onTerminate: onEnd,
    onRelease: onEnd,
    onMove: ({ delta }) => {
      const frameChange = Math.round(delta[0] * args.sensitivity);
      const frameIndex = Math.min(
        Math.max(lastFrame + frameChange, 0),
        args.frameCount
      );
      dispatch(setFrame(frameIndex));
    },
  });

  const { width: windowWidth } = useWindowSize();

  const size = useMemo(() => {
    if (windowWidth === null) {
      return null;
    } else {
      const width = windowWidth * imgWidthFraction;
      const left = (windowWidth - width) / 2;
      return {
        width,
        height: (windowWidth * imgWidthFraction) / 1.48,
        left: isFullScreen ? left : 0,
      };
    }
  }, [isFullScreen, windowWidth]);

  return {
    bind: rotationEnable && !isFullScreen ? bind : {},
    currentFrame,
    size,
  };
}

export function useVisoSize() {
  const { width: windowWidth } = useWindowSize();

  return useMemo(() => {
    if (windowWidth === null) {
      return null;
    } else {
      const width = windowWidth * imgWidthFraction;
      return {
        width,
        height: (windowWidth * imgWidthFraction) / 1.48,
      };
    }
  }, [windowWidth]);
}

function GoBack(args: (onClick?: () => void) => JSX.Element) {
  const canGoBack = useAppSelector((state) => state.viso.status !== "detail");
  const { changeRoute } = useRouter();
  const dispatch = useAppDispatch();
  return args(
    canGoBack
      ? () => {
          changeRoute(Routes.biorepeel, { showPrevious: false });
          dispatch(resetViso());
        }
      : undefined
  );
}

function GoUp(args: (onClick: () => void) => JSX.Element) {
  const dispatch = useAppDispatch();
  const { currentFrame, isFullScreen } = useAppSelector((state) => ({
    currentFrame: state.viso.currentFrame,
    isFullScreen: state.viso.status === "fullscreen",
  }));
  const { changeRoute } = useRouter();

  const dispatchNextFrame = useCallback(
    (frame: number) => {
      if (frame === 21) {
        dispatch(setFrame(frame));
        dispatch(goBack());
      } else {
        dispatch(setFrame(frame));
        setTimeout(
          () =>
            frame > 21
              ? dispatchNextFrame(frame - 1)
              : dispatchNextFrame(frame + 1),
          10
        );
      }
    },
    [dispatch]
  );

  return args(() => {
    if (isFullScreen) {
      dispatch(unselectePoint());
      changeRoute(Routes.inostriprodotti, { showPrevious: false });
      dispatch(resetViso());
    } else {
      dispatch(unselectePoint());
      dispatchNextFrame(currentFrame);
    }
  });
}
