/* eslint-disable jsx-a11y/alt-text */
import { Box, BoxProps, Grid, GridProps, TypographyProps } from "@mui/material";
import { Colors } from "../../styles/theme";
import {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { ZIndexes } from "../InternalPage/InternalPage";

interface ScrollableElement {
  title?: string;
  subtitle?: TypographyProps["children"];
  element: JSX.Element;
}

interface NewVerticalScrollableCardProps {
  color: Colors;
  sx?: BoxProps["sx"];
  readyAfter?: number;
  selected?: number;
  hover?: GridProps["children"];
  elements: Array<ScrollableElement>;
  backgroundColor?: CSSProperties["backgroundColor"];
  width?: number;
  height?: number;
  onSelectedIndexChange?: (index: number) => void;
}
// 405 altezza card
// 223 altezza contenuto

function calculateIndex(
  height: number | undefined,
  elements: Array<any>,
  scrollTop: number
) {
  if (height === undefined || elements.length < 2) {
    return 0;
  } else {
    return Math.min(
      Math.max(Math.round(scrollTop / height) + 0, 0),
      elements.length - 1
    );
  }
}

export function NewVerticalScrollableCard(
  props: NewVerticalScrollableCardProps
) {
  const ref = useRef<HTMLDivElement>(null);
  const scrollableRef = useRef<HTMLDivElement>(null);
  const [scrollTop, setScrollTop] = useState(
    (props?.height ?? 0) * (props.selected ?? 0)
  );
  const [canScroll, setCanScroll] = useState(false);

  const selectedIndex = useMemo(() => props.selected ?? 0, [props.selected]);

  useEffect(() => {
    setTimeout(() => setCanScroll(true), (props.readyAfter ?? 0) * 1000);
  }, [props.readyAfter]);

  const onScroll = useCallback(
    (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
      if (canScroll) {
        const element = e.target as HTMLDivElement;
        const scrollTop = element.scrollTop;
        setScrollTop(scrollTop);
        const index = calculateIndex(
          ref.current?.getBoundingClientRect().height,
          props.elements,
          scrollTop
        );
        props.onSelectedIndexChange?.call(undefined, index);
      }
    },
    [canScroll, props.elements, props.onSelectedIndexChange]
  );

  useEffect(() => {
    if (canScroll) {
      let timeout: NodeJS.Timeout | undefined;
      timeout = setTimeout(() => {
        const top =
          (ref.current?.getBoundingClientRect().height ?? 0) * selectedIndex;
        scrollableRef.current?.scrollTo({
          top,
          behavior: "smooth",
        });
      }, 100);
      return () => clearTimeout(timeout);
    } else {
      const top =
        (ref.current?.getBoundingClientRect().height ?? 0) * selectedIndex;
      scrollableRef.current?.scrollTo({
        top,
        left: 0,
      });
    }
  }, [props?.height, scrollTop, selectedIndex, canScroll, props.elements]);

  return (
    <Box
      ref={ref}
      component="div"
      style={{
        // backgroundColor: props.backgroundColor,
        width: props.width ?? "100%",
        height: props.height ?? "100%",
        overflowY: "hidden",
        overflowX: "hidden",
        position: "relative",
      }}
      sx={props.sx}
    >
      {/* VERTICAL DOTS */}
      {props.elements.length > 1 && (
        <Grid position={"absolute"} top={0} left={0} zIndex={ZIndexes.body + 1}>
          <Grid item>
            <Grid container direction={"column"} gap={1} pt={4} px={3}>
              {props.elements.map((_, i) => (
                <Circle
                  key={i}
                  color={props.color}
                  size={12}
                  active={i === selectedIndex}
                />
              ))}
            </Grid>
          </Grid>
        </Grid>
      )}
      {/* VERTICAL SCROLL  */}
      {props.elements.length > 1 && (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            position: "absolute",
            overflowY: "auto" /* Enable scrolling for content */,
            // backgroundColor: "green", // props.backgroundColor,
            ZIndex: ZIndexes.body + 1,
          }}
          ref={scrollableRef}
          top={0}
          right={0}
          onScroll={onScroll}
        >
          {props.elements.map((e, i) => (
            <Box
              key={i}
              position={"relative"}
              style={{
                width: props.width ?? "100%",
                height: props.height ?? "100%",
              }}
            >
              {e.element}
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
}
export interface CircleProps {
  color: Colors;
  size: number;
  active?: boolean;
  border?: number;
}
export function Circle(props: CircleProps) {
  return (
    <Box
      sx={{
        backgroundColor: `${props.color}.main`,
        borderColor: `${props.color}.main`,
        border: props.border,
        opacity: props.active ? 1 : 0.5,
      }}
      width={props.size}
      height={props.size}
      borderRadius={props.size}
    />
  );
}
