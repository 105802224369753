/* eslint-disable jsx-a11y/alt-text */
import { Box, Grid, Typography } from "@mui/material";
import {
  InternalPage,
  ZIndexes,
} from "../../components/InternalPage/InternalPage";
import {
  useIsRouteVisible,
  useRouter,
} from "../../components/MainRouter/MainRouter";
import { ImageKeys } from "../../utils/assets";
import { Routes } from "../../utils/routes";
import { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import { TextCard } from "../../components/Card/TextCard";
import { Line } from "../../components/Atoms/Lines";
import {
  HorizontalGrid,
  VerticalGrid,
} from "../../components/HorizontalGrid/HorizontalGrid";
import { useWindowSize } from "@uidotdev/usehooks";
import { GenericCard } from "../../components/Card/GenericCard";
import { VideoKeys } from "../../utils/assets";
import { UltherapyLogo } from "../../components/Logos/UltherapyLogo";
import { NewVerticalScrollableCard } from "../../components/Card/NewVerticalScrollableCard";
import { KeyMessages } from "../../components/KeyMessages";
import { BeforeAndAfter } from "../../components/Card/BeforeAndAfter";
import { openModal } from "../../redux/modal.reducer";
import { useAppDispatch } from "../../redux/hooks";
import { useAsset, useSingleAsset } from "../../components/AsssetsLoader";

import {
  BackgroundIntro,
  Header,
  Inner,
  TopDescription,
} from "./BioRepeelPage";
import { heightToWidth, isSafari } from "../../utils/functions";
import { useIsIntroViewed } from "../../redux/pages.reducer";

export function EmptyCard() {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: "#F6F6F6",
        opacity: "0.5",
      }}
    ></div>
  );
}

export function UltherapyPage() {
  const isVisible = useIsRouteVisible(Routes.ultherapy);
  const { changeRoute } = useRouter();
  const videoIntro = useSingleAsset<VideoKeys>("ultherapy_video_intro");

  if (!isVisible) return <></>;

  return (
    <InternalPage
      color="ultherapyYellow"
      logoFill="black"
      backgroundColor="white"
      goUpper={() =>
        changeRoute(Routes.inostriprodotti, { showPrevious: false })
      }
      goBack={() => changeRoute(Routes.bocoture, { showPrevious: false })}
      goNext={() => changeRoute(Routes.biorepeel, { showPrevious: false })}
      sectionHeader={
        <Header id="ultherapy" color="ultherapyYellow">
          <Typography
            variant="h3"
            textAlign="center"
            sx={{ color: "ultherapyYellow.main" }}
          >
            <UltherapyLogo width={"20rem"} fill="black" />
          </Typography>
        </Header>
      }
    >
      <Inner
        id="ultherapy"
        color="ultherapyYellow"
        children={<UltherapyInternal />}
      />
      <BackgroundIntro id="ultherapy" type="video" src={videoIntro} />
    </InternalPage>
  );
}

function UltherapyInternal() {
  const [ultherapy_banner_sup] = useAsset<VideoKeys>(["ultherapy_banner_sup"]);
  const ref = useRef<HTMLVideoElement>(null);

  return (
    <>
      <Box
        position="fixed"
        top={0}
        zIndex={ZIndexes.body}
        left={0}
        width={"100%"}
        height={"100%"}
      >
        <VerticalGrid type="container" rows={["60%", "40%"]}>
          <VerticalGrid type="item" sx={{ position: "relative" }}>
            <video
              crossOrigin="anonymous"
              ref={ref}
              src={ultherapy_banner_sup}
              style={{
                width: "100%",
                height: "auto",
                position: "absolute",
                bottom: 0,
                right: 0,
              }}
              autoPlay
              loop
            />
            <Box
              position={"absolute"}
              width={"100%"}
              height={"100%"}
              left={0}
              top={"8rem"}
              zIndex={ZIndexes.dialog}
            >
              <Grid
                container
                direction={"column"}
                width={"100%"}
                height={"100%"}
              >
                <Grid item>
                  <Grid
                    container
                    direction={"row"}
                    justifyContent={"center"}
                    alignContent={"center"}
                  >
                    <Grid item xs={6}>
                      <TopDescription
                        color="ultherapyYellow"
                        id={Routes.ultherapy}
                      >
                        L'unica tecnologia ad ultrasuoni microfocalizzati e
                        visualizzazione ecografica in tempo reale (MFU-V) per
                        risultati naturali, visibili e ripetibili.
                        <sub>1,2,3</sub>
                      </TopDescription>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <UltherapyIcons />
          </VerticalGrid>
          <VerticalGrid type="item">
            <UltherapyBottom />
          </VerticalGrid>
        </VerticalGrid>
      </Box>

      {/**SCOMPARE DOPO 3 SECONDI */}
      <Box
        position="fixed"
        bottom={0}
        zIndex={ZIndexes.dialog}
        left={0}
        width={"100%"}
        sx={{
          animation: "blur 10s ease-in-out 1s",
          animationDirection: "reverse",
          animationFillMode: "forwards",
        }}
      >
        <Grid
          container
          direction={"row"}
          width={"100%"}
          justifyContent={"center"}
        >
          <Grid item xs={6}>
            <Typography
              textAlign={"center"}
              width={"100%"}
              color={"#8A8A8A"}
              fontSize={9}
              paddingBottom={2}
            >
              1. Fabi SG, Joseph J, Sevi J, Green JB, Peterson JD. Optimizing
              patient outcomes by customizing treatment with microfocused
              ultrasound with visualization: gold standard consensus guidelines
              from an expert panel. J Drugs Dermatol. 2019;18(5):426-432. 2.
              Ulthera Instructions for Use. 3. Ulthera release: Ultherapy®
              décolletage treatment now FDA-cleared. BioSpace website.
              https://www.biospace.com/article/releases/
              ulthera-release-ultherapy-and-0174-d%C3%A9colletage-treatment-now-fda-cleared-/.
              Accessed January 21, 2020.
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

function UltherapyIcons() {
  const show = useIsIntroViewed(Routes.ultherapy);
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (show) {
      const timeout = setTimeout(() => {
        videoRef?.current?.play().catch(() => {});
      }, 1500);
      return () => clearTimeout(timeout);
    }
  }, [show]);

  return (
    <WebmVideo
      src="ultherapy_icons"
      ref={videoRef}
      style={{
        position: "absolute",
        left: "4rem",
        bottom: "2rem",
        zIndex: ZIndexes.dialog,
      }}
      width={"auto"}
      height={"70%"}
    />
  );
}

export const WebmVideo = forwardRef(
  (
    {
      src,
      ...props
    }: Omit<
      React.DetailedHTMLProps<
        React.VideoHTMLAttributes<HTMLVideoElement>,
        HTMLVideoElement
      >,
      "children" | "ref"
    > & { src: VideoKeys },
    ref: React.ForwardedRef<HTMLVideoElement>
  ) => {
    const [videoMov, videoWebm] = useAsset([src, `${src}_webm`]);
    return (
      <video {...props} ref={ref}>
        {isSafari() && <source src={videoMov} type="video/mp4" />}
        {!isSafari() && <source src={videoWebm} type="video/webm" />}
      </video>
    );
  }
);

function UltherapyBottom() {
  const [ultherapy_banner_1, ultherapy_banner_2] = useAsset<VideoKeys>([
    "ultherapy_banner_1",
    "ultherapy_banner_2",
  ]);

  const videoRef1 = useRef<HTMLVideoElement>(null);
  const videoRef2 = useRef<HTMLVideoElement>(null);

  const [selected, setSelected] = useState<number>(0);
  const { width } = useWindowSize();

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (selected !== 1 && videoRef1.current) {
        videoRef1.current!.pause();
        videoRef1.current!.currentTime = 0;
      }
      if (selected !== 2 && videoRef2.current) {
        videoRef2.current!.pause();
        videoRef2.current!.currentTime = 0;
      }
      if (selected === 1 && videoRef1.current) {
        videoRef1.current!.play().catch(() => {});
        videoRef1.current!.currentTime = 0;
      } else if (selected === 2 && videoRef2.current) {
        videoRef2.current!.play().catch(() => {});
        videoRef2.current!.currentTime = 0;
      }
    }, 100);
    return () => clearTimeout(timeout);
  }, [selected]);

  if (width === null) return <></>;

  return (
    <NewVerticalScrollableCard
      color="ultherapyDots"
      selected={selected}
      onSelectedIndexChange={setSelected}
      sx={{
        color: "black",
      }}
      elements={[
        {
          element: (
            <Box width={"100%"} height={"100%"} pl={5} pb={5}>
              <KeyMessages
                color="ultherapyYellow"
                description={
                  <Typography textAlign={"center"}>
                    Ultherapy® è un trattamento sicuro e altamente
                    personalizzabile che consente un graduale lifting non
                    chirurgico ad effetto naturale<sup>1</sup>.
                  </Typography>
                }
                messages={[
                  {
                    type: "line",
                    title: "Gold Standard",
                    subtitle: (
                      <>
                        del lifting non chirurgico
                        <sup>1</sup>
                      </>
                    ),
                  },
                  {
                    type: "line",
                    title: "4 approvazioni FDA",
                    subtitle: (
                      <>
                        Sopracciglio, collo, sottomento e décolleté
                        <sup>2,3</sup>.
                      </>
                    ),
                  },
                  {
                    type: "line",
                    title: "MFU-V",
                    subtitle: (
                      <>
                        L'unica tecnologia con visualizzazione ecografica in
                        tempo reale<sup>1</sup>.
                      </>
                    ),
                  },
                ]}
              />
            </Box>
          ),
        },
        {
          element: (
            <UltherapySecondBottomPage>
              <video
                crossOrigin="anonymous"
                src={ultherapy_banner_1}
                ref={videoRef1}
                style={{
                  width: "100%",
                  height: "auto",
                  position: "absolute",
                  bottom: 0,
                  zIndex: ZIndexes.background,
                }}
              />
            </UltherapySecondBottomPage>
          ),
        },
        {
          element: (
            <Box
              position={"absolute"}
              top={0}
              left={0}
              width={"100%"}
              height={"100%"}
              sx={{ zIndex: ZIndexes.background }}
            >
              <video
                ref={videoRef2}
                crossOrigin="anonymous"
                src={ultherapy_banner_2}
                style={{
                  height: "auto",
                  width: "100%",
                }}
              />
            </Box>
          ),
        },
        {
          element: <UltherapyPage4 />,
        },
      ]}
    />
  );
}

function UltherapyPage4() {
  const [ultherapy_collo, ultherapy_decolte, ultherapy_viso, ultherapy_method] =
    useAsset<VideoKeys>([
      "ultherapy_collo",
      "ultherapy_decolte",
      "ultherapy_viso",
      "ultherapy_method",
    ]);
  const [ultherapy_a1_before, ultherapy_a1_after] = useAsset<ImageKeys>([
    "ultherapy_a1_before",
    "ultherapy_a1_after",
  ]);

  const dispatch = useAppDispatch();
  const { height } = useWindowSize();
  const cardHeight = useMemo(
    () => (height != null ? height * 0.28 : null),
    [height]
  );

  return (
    <Box pl={7} pt={3} height={"100%"}>
      {cardHeight && (
        <HorizontalGrid
          type="container"
          sx={{
            height: cardHeight + 48,
          }}
          gap={1}
          firstColumn={`${heightToWidth(cardHeight) * 0.5}px`}
          columnSize={heightToWidth(cardHeight)}
          columns={2}
        >
          <HorizontalGrid type="item">
            <TextCard color="ultherapyYellow" title="Before & After">
              <GenericCard
                byHeight
                color="ultherapyYellow"
                mediaChild={(rect) => (
                  <Grid
                    container
                    justifyContent={"center"}
                    style={{ width: "100%", height: rect.height }}
                  >
                    <Grid item style={{ width: `${rect.height * 0.6}px` }}>
                      <BeforeAndAfter
                        rect={rect}
                        before={ultherapy_a1_before}
                        after={ultherapy_a1_after}
                      />
                    </Grid>
                  </Grid>
                )}
                onClick={(rect) => {
                  if (rect === undefined) return;
                  dispatch(
                    openModal({
                      modalData: {
                        type: "beforeAndAfter",
                        images: [
                          "ultherapy_a1",
                          "ultherapy_a2",
                          "ultherapy_b1",
                          "ultherapy_b2",
                          "ultherapy_c1",
                          "ultherapy_c2",
                        ],
                      },
                      rect,
                    })
                  );
                }}
              >
                <Line color="ultherapyYellow" height={6} />
              </GenericCard>
            </TextCard>
          </HorizontalGrid>
          <HorizontalGrid type="item">
            <TextCard color="ultherapyYellow" title="Come agisce">
              <GenericCard
                byHeight
                color="ultherapyYellow"
                startTime={77}
                video={ultherapy_method}
                sx={{ backgroundColor: "white" }}
              />
            </TextCard>
          </HorizontalGrid>
          <HorizontalGrid type="item">
            <TextCard color="ultherapyYellow" title="Video trattamenti">
              <GenericCard
                byHeight
                color="ultherapyYellow"
                startTime={4}
                video={ultherapy_collo}
                onClick={(rect, id) => {
                  return (
                    rect &&
                    dispatch(
                      openModal({
                        modalData: {
                          type: "carousel",
                          items: [
                            {
                              type: "video",
                              src: ultherapy_collo,
                              style: { height: "100%", width: "auto" },
                            },
                            {
                              type: "video",
                              src: ultherapy_decolte,
                              style: { height: "100%", width: "auto" },
                            },
                            {
                              type: "video",
                              src: ultherapy_viso,
                              style: { height: "100%", width: "auto" },
                            },
                          ],
                        },
                        rect,
                        id,
                      })
                    )
                  );
                }}
                sx={{ backgroundColor: "white" }}
              />
            </TextCard>
          </HorizontalGrid>
        </HorizontalGrid>
      )}
    </Box>
  );
}

function UltherapySecondBottomPage(props: { children: JSX.Element }) {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        position: "relative",
      }}
    >
      <Grid container direction={"row"} justifyContent={"center"}>
        <Grid item xs={6}>
          <Typography textAlign={"center"} pt={5}>
            <strong>Ultherapy</strong> è stato scelto, per la{" "}
            <strong>10ma volta consecutiva</strong>, tra 10.000 trattamenti
            valutati dalla redazione di NewBeauty per l’unicità della sua
            tecnologia che consente al medico di personalizzare il lifting in
            base alle specifiche esigenze del paziente in una sola seduta e
            senza tempi di recupero.
          </Typography>
        </Grid>
      </Grid>
      {props.children}
    </div>
  );
}
