/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { useState, useMemo, useRef, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import {
  ModalCarouselData,
  ModalDOMRect,
  Rect,
  closeModal,
} from "../../redux/modal.reducer";
import { useFullScreenModalSize } from "../../utils/hooks";
import { Carousel, useCarouselHandler } from "./generic.carousel";
import { transitionEnabled } from "../../constants";
import { ZIndexes } from "../InternalPage/InternalPage";

function getElementRect(args: { rect: ModalDOMRect; id?: string }) {
  if (args.rect) return args.rect;
  else if (args.id !== undefined)
    return document.getElementById(args.id)?.getRect();
  return undefined;
}

const duration = 1;

export function ModalCarousel(
  props: ModalCarouselData & { rect: ModalDOMRect; id?: string }
) {
  const style = useFullScreenModalSize(props.rect);

  const { next, prev, activeIndex, setActiveIndex } = useCarouselHandler(
    props.items.length,
    props.initial
  );
  const [rect, setRect] = useState<Rect | undefined>();
  const dispatch = useAppDispatch();

  const { close, animated } = useAppSelector((state) => ({
    close: state.modal.close,
    animated: state.modal.animated,
    fullscreen: state.modal.fullscreen,
  }));
  const boxRef = useRef<HTMLDivElement>(null);

  const imgRef = useRef<HTMLImageElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const elRect = getElementRect(props);
    if (animated) {
      setRect(elRect);
      const timeout = setTimeout(() => setRect(boxRef.current?.getRect()), 100);
      return () => clearTimeout(timeout);
    }
  }, [animated, props.id, props.rect]);

  const item = useMemo(() => {
    const currentItem = props.items[activeIndex];
    switch (currentItem.type) {
      case "image":
        return (
          <img
            id={props.id !== undefined ? `${props.id}-img` : undefined}
            src={props.items[activeIndex].src}
            style={{
              ...style,
              visibility: !animated ? "visible" : "hidden",
              ...currentItem.style,
            }}
            ref={imgRef}
          />
        );
      case "video":
        return (
          <video
            crossOrigin="anonymous"
            src={props.items[activeIndex].src}
            autoPlay={!animated}
            controls={!animated}
            style={{
              ...style,
              visibility: !animated ? "visible" : "hidden",
              ...currentItem.style,
            }}
            ref={videoRef}
          />
        );
      default:
        return <></>;
    }
  }, [activeIndex, animated, props.items, style]);

  useEffect(() => {
    if (close) {
      dispatch(closeModal());
    }
  }, [close, dispatch]);

  return (
    <>
      <Carousel
        ref={boxRef}
        next={next}
        prev={prev}
        setActiveIndex={setActiveIndex}
        children={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              width: "100%",
              height: "100%",
            }}
            children={item}
          />
        }
        items={props.items.length}
        activeIndex={activeIndex}
      />
      {rect && <El rect={rect} item={props.items[activeIndex]} />}
    </>
  );
}

const El = React.memo(
  ({
    rect,
    item,
  }: {
    rect: ModalDOMRect | undefined;
    item: ModalCarouselData["items"][0];
  }) => {
    if (rect === undefined) return <></>;
    return (
      <div
        style={{
          position: "fixed",
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          transition: !transitionEnabled
            ? undefined
            : `width ${duration}s linear,height ${duration}s linear,top ${duration}s linear,left ${duration}s linear`,
          width: rect.width,
          height: rect.height,
          left: rect.left,
          top: rect.top,
          zIndex: ZIndexes.dialog,
          visibility: "visible",
        }}
      >
        {item.type === "image" && (
          <img
            src={item.src}
            style={{
              height: "auto",
              backgroundColor: "white",
              ...item.style,
            }}
          />
        )}
        {item.type === "video" && (
          <video
            crossOrigin="anonymous"
            src={item.src}
            autoPlay
            controls
            style={{
              width: "auto",
              height: rect.height,
              ...item.style,
            }}
          />
        )}
      </div>
    );
  }
);
