import { images } from "./images";
import { videos } from "./videos";

export * from "./images";
export * from "./videos";

export enum Quality {
  low = "low",
  medium = "medium",
  high = "high",
}

function convertImageUrl(url: string, quality: Quality) {
  switch (quality) {
    case Quality.low:
      return url + "?quality=60";
    case Quality.medium:
      return url + "?quality=80";
    default:
      return url;
  }
}

function convertVideoUrl(url: string, quality: Quality) {
  switch (quality) {
    case Quality.low:
    case Quality.medium:
      return url.replace("/videos", "/compressed_videos");
    default:
      return url;
  }
}

export function getAssets() {
  const urlParams = new URLSearchParams(window.location.search);
  // eslint-disable-next-line no-restricted-globals
  const qualityBySize = screen.width <= 960 ? Quality.low : Quality.high;
  const quality = (urlParams.get("quality") as Quality | null) ?? qualityBySize;
  let result = new Map<string, string>();
  Object.entries(images).forEach(([key, value]) =>
    result.set(key, convertImageUrl(value, quality))
  );
  Object.entries(videos).forEach(([key, value]) =>
    result.set(key, convertVideoUrl(value, quality))
  );
  return result;
}
