import { Box, BoxProps, Grid, GridProps, Typography } from "@mui/material";
import { Line } from "../Atoms/Lines";
import { Colors } from "../../styles/theme";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { ReturnIcon } from "../Icons/ReturnIcon";
import { LeftIcon, RightIcon } from "../Icons/ArrowUP";
import { Logo } from "../Atoms/Logo";
import { transitionEnabled } from "../../constants";
import { HomeIcon } from "../Icons/HomeIcon";
import { useRouter } from "../MainRouter/MainRouter";
import { Routes } from "../../utils/routes";
import { VerticalGrid } from "../HorizontalGrid/HorizontalGrid";

export enum ZIndexes {
  hide = -1000,
  background = -10,
  body = 10,
  sidebar = 20,
  intro = 22,
  topNavigation = 25,
  navigation = 30,
  dialog = 40,
  everywhere = 50,
}

export interface InternalPageProps {
  textColor?: React.CSSProperties["color"];
  logoFill?: React.CSSProperties["fill"];
  background?: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >["children"];
  backgroundColor?: React.CSSProperties["backgroundColor"];
  backgroundStyle?: React.CSSProperties;
  color?: Colors;
  subsection?: JSX.Element | string;
  sectionHeader?: JSX.Element;
  rect?: DOMRect;
  children?: GridProps["children"];
  goHome?: () => void;
  goUpper?: () => void;
  goUpBuilder?: (args: (onClick: () => void) => JSX.Element) => JSX.Element;
  goBack?: () => void;
  goBackBuilder?: (args: (onClick?: () => void) => JSX.Element) => JSX.Element;
  goNext?: () => void;
}

export function InternalPage(props: InternalPageProps) {
  const [rect, setRect] = useState(props.rect);
  const headerRef = useRef<HTMLDivElement>(null);

  const { changeRoute } = useRouter();

  const goHome = useCallback(() => {
    changeRoute(Routes.home, { showPrevious: false });
    props.goHome?.call(undefined);
  }, [changeRoute, props.goHome]);

  useEffect(() => {
    if (headerRef.current) {
      setRect(headerRef.current.getBoundingClientRect());
    }
  }, []);

  return (
    <>
      {rect && props.sectionHeader && props.color && (
        <div
          style={{
            position: "absolute",
            top: rect.top,
            left: rect.left,
            width: rect.width,
            height: rect.height,
            zIndex: ZIndexes.topNavigation,
            transition: !transitionEnabled ? undefined : "all 1s ease-in-out",
          }}
        >
          {props.sectionHeader ?? (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              direction="column"
            >
              <Line color={props.color} height={15} />
              <Grid item py={3} children={props.sectionHeader} />
            </Grid>
          )}
        </div>
      )}
      {/* BACKGROUND */}
      <div
        style={{
          ...props.backgroundStyle,
          position: "fixed",
          left: 0,
          top: 0,
          zIndex: ZIndexes.background,
          height: "100%",
          width: "100%",
          backgroundColor: props.backgroundColor,
        }}
        children={props.background}
      />
      {/* LEFT SIDE */}
      <Box
        position={"absolute"}
        zIndex={ZIndexes.navigation}
        color={"black"}
        height={"100%"}
        left={0}
      >
        <Grid
          container
          direction="column"
          justifyContent={"center"}
          width={"100%"}
          height={"100%"}
        >
          <Grid item>
            <IconBox onClick={goHome} icon={<HomeIcon height={"60%"} />} />
          </Grid>
          <Grid item>
            {props.goBackBuilder ? (
              props.goBackBuilder((onClick) => (
                <IconBox onClick={onClick} icon={<LeftIcon height={"70%"} />} />
              ))
            ) : (
              <IconBox
                onClick={props.goBack}
                icon={<LeftIcon height={"70%"} />}
              />
            )}
          </Grid>
          <Grid item>
            {props.goUpBuilder ? (
              props.goUpBuilder((onClick) => (
                <IconBox
                  onClick={onClick}
                  icon={<ReturnIcon height={"70%"} />}
                />
              ))
            ) : (
              <IconBox
                onClick={props.goUpper}
                icon={<ReturnIcon height={"70%"} />}
              />
            )}
          </Grid>
        </Grid>
      </Box>
      {/* RIGHT SIDE */}
      <Box
        position={"absolute"}
        zIndex={ZIndexes.navigation}
        color={"black"}
        height={"100%"}
        right={0}
      >
        <Grid
          container
          direction="column"
          justifyContent={"center"}
          alignContent={"space-between"}
          width={"100%"}
          height={"100%"}
        >
          <Grid item visibility={"hidden"}>
            <IconBox icon={<RightIcon height={"70%"} />} />
          </Grid>
          <Grid item>
            <IconBox
              onClick={props.goNext}
              icon={<RightIcon height={"70%"} />}
            />
          </Grid>
          <Grid item visibility={"hidden"}>
            <IconBox icon={<RightIcon height={"70%"} />} />
          </Grid>
        </Grid>
      </Box>

      <VerticalGrid type="container" rows={["60px", "auto"]}>
        <VerticalGrid type="item">
          <Grid
            container
            direction="row"
            width="100%"
            style={{
              color: props.textColor,
            }}
          >
            <Grid item xs={3}>
              <Grid container gap={1}>
                <Grid item pl={4} py={1}>
                  <Logo fill={props.logoFill ?? "white"} height={30} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={4} ref={headerRef} minHeight={60}></Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={2} sx={{ zIndex: ZIndexes.navigation }}>
              <Box component="div" pt={2}>
                {props.subsection && (
                  <>
                    {props.color && <Line color={props.color} height={2} />}
                    <Typography textAlign={"left"}>
                      <i>{props.subsection}</i>
                    </Typography>
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
        </VerticalGrid>
        <VerticalGrid type="item">
          <div
            style={{ color: "black", width: "100%", height: "100%" }}
            children={props.children}
          />
        </VerticalGrid>
      </VerticalGrid>
    </>
  );
}

interface IconBoxProps {
  onClick?: BoxProps["onClick"];
  icon: JSX.Element;
}

function IconBox(props: IconBoxProps) {
  return (
    <Box
      component="div"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        flexWrap: "wrap",
        width: "3rem",
        height: "3rem",
        backgroundColor: "rgba(80,80,80,0.5)",
        cursor: "pointer",
        color: "white",
        opacity: props.onClick === undefined ? 0.2 : 1,
        zIndex: ZIndexes.navigation,
      }}
      onClick={props.onClick}
      children={props.icon}
    />
  );
}
