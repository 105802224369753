import { Grid, Typography } from "@mui/material";
import {
  ObiettivoTrattamento,
  SelectionPointDesc,
} from "../../pages/prodotti/VisoPage";
import { Rectangle } from "../Atoms/Rectangle";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { ZIndexes } from "../InternalPage/InternalPage";
import { useAsset, useSingleAsset } from "../AsssetsLoader";
import { Prodotto } from "../../constants/viso.constants";
import { ImageKeys } from "../../utils/assets";
import { ModalDOMRect, openModal } from "../../redux/modal.reducer";
import { useRouter } from "../MainRouter/MainRouter";
import { Routes } from "../../utils/routes";
import { setBelotero } from "../../redux/belotero.reducer";
import { transitionEnabled } from "../../constants";
import { resetViso } from "../../redux/viso.reducer";

export function VisoSidebar() {
  const animation = "blur 3s ease-out";

  const { multipleSelectionPoint, area, isDetail } = useAppSelector(
    (state) => ({
      multipleSelectionPoint: state.viso.selectedPoint,
      area: state.viso.layer?.name,
      isDetail: state.viso.status === "detail",
    })
  );

  const [obiettivo, setObiettivo] = useState<
    ObiettivoTrattamento | undefined
  >();

  const [selectedPoint, setSelectedPoint] = useState<
    SelectionPointDesc | undefined
  >();

  useEffect(() => {
    if (multipleSelectionPoint !== undefined) {
      if (multipleSelectionPoint.desc instanceof Array) {
        setSelectedPoint(undefined);
      } else {
        setSelectedPoint(multipleSelectionPoint.desc);
      }
    } else {
      setSelectedPoint(undefined);
    }
  }, [multipleSelectionPoint]);

  useEffect(() => {
    if (
      selectedPoint?.obiettivi !== undefined &&
      selectedPoint?.obiettivi.length > 0
    ) {
      setObiettivo(selectedPoint?.obiettivi[0]);
    } else {
      setObiettivo(undefined);
    }
  }, [selectedPoint?.obiettivi]);

  return (
    <div
      style={{
        color: "black",
        position: "absolute",
        right: 0,
        top: 0,
        zIndex: ZIndexes.sidebar,
        height: "100%",
        width: isDetail ? "42%" : 0,
        backgroundColor: "#dfdfdf",
        transition: !transitionEnabled ? undefined : "width 1s ease-in-out",
      }}
    >
      <div style={{ height: "100%" }}>
        <Grid container direction="column" pl={8} pb={2} pt={20} gap={2}>
          <Grid item>
            <Typography variant="h5" textTransform="uppercase">
              <strong>3D FACE INJECTION</strong>
            </Typography>
          </Grid>

          <Grid item>
            {multipleSelectionPoint?.desc instanceof Array && (
              <Grid container py={2}>
                <Typography>
                  Seleziona il distretto che vuoi approfondire
                </Typography>
                <Grid item xs={12}>
                  <Grid container gap={1}></Grid>
                </Grid>
              </Grid>
            )}
            {area && (
              <Grid container direction="row">
                <Grid item xs={6}>
                  <Typography
                    sx={{ color: "debianRed.main" }}
                    variant="h5"
                    visibility={
                      selectedPoint?.showAreaName ?? true ? "visible" : "hidden"
                    }
                  >
                    <strong>
                      <i>Area</i>
                    </strong>
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="h5"
                    visibility={
                      selectedPoint?.showAreaName ?? true ? "visible" : "hidden"
                    }
                  >
                    <i>{area}</i>
                  </Typography>
                </Grid>
              </Grid>
            )}
            {(selectedPoint?.distretto ||
              multipleSelectionPoint?.desc instanceof Array) && (
              <Grid container direction="row">
                <Grid item xs={6}>
                  <Typography sx={{ color: "debianRed.main" }} variant="h5">
                    <strong>
                      <i>Distretto anatomico</i>
                    </strong>
                  </Typography>
                </Grid>
                <Grid item style={{ animation }} xs={5}>
                  {multipleSelectionPoint?.desc instanceof Array ? (
                    <Grid container direction={"column"} gap={1}>
                      <Grid item>
                        <Typography variant="h5">
                          <i>{multipleSelectionPoint.desc[0].distretto}</i>
                        </Typography>
                      </Grid>
                      {multipleSelectionPoint?.desc.map((e, i) => (
                        <Grid key={i} item>
                          <Rectangle
                            active={e === selectedPoint}
                            activeColor="debianRed.main"
                            disabledColor="#d2d2d2"
                            variant="h5"
                            text={
                              <span style={{ textAlign: "start" }}>
                                <i>{e.distrettoOption}</i>
                              </span>
                            }
                            visibility={"visible"}
                            onClick={() => {
                              setSelectedPoint(e);
                            }}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  ) : (
                    <Typography variant="h5">
                      <i>{selectedPoint?.distretto}</i>
                    </Typography>
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>

        <div style={{ height: "100%", backgroundColor: "#d8d8d8" }}>
          {selectedPoint?.obiettivi && (
            <Grid container pl={8} py={2}>
              <Typography>Seleziona l'obiettivo di trattamento</Typography>
            </Grid>
          )}
          <Grid container gap={1} pl={8}>
            {new Array(4).fill(0).map((_, i) => {
              // props.obiettivi?.map((e, i) => (
              const currentObiettivo: ObiettivoTrattamento | undefined =
                (selectedPoint?.obiettivi ?? [])[i];
              return (
                <Grid
                  key={i}
                  item
                  xs={5}
                  style={{ animation: "blur 3s ease-out" }}
                >
                  <Rectangle
                    active={obiettivo === currentObiettivo}
                    activeColor="debianRed.main"
                    disabledColor="#d2d2d2"
                    text={currentObiettivo?.obiettivo ?? "HIDDEN"}
                    visibility={
                      currentObiettivo === undefined ? "hidden" : "visible"
                    }
                    onClick={
                      currentObiettivo === undefined
                        ? undefined
                        : () => setObiettivo(currentObiettivo)
                    }
                  />
                </Grid>
              );
              // ));
            })}
          </Grid>
          {obiettivo && (
            <>
              <Grid
                container
                gap={1}
                pl={8}
                pt={2}
                style={{ animation: "blur 3s ease-out" }}
              >
                <Grid item xs={3}>
                  <Typography>
                    <strong>Prodotto</strong>
                  </Typography>
                  <Typography>{obiettivo.prodotto}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography>
                    <strong>Ago</strong>
                  </Typography>
                  {obiettivo.ago && <Typography>{obiettivo.ago}</Typography>}
                </Grid>
                <Grid item xs={3}>
                  <Typography>
                    <strong>Cannula</strong>
                  </Typography>
                  {obiettivo.cannula && (
                    <Typography>{obiettivo.cannula}</Typography>
                  )}
                </Grid>
              </Grid>
              <Grid container gap={1} pl={8} pt={2} style={{ animation }}>
                <Grid item xs={3}>
                  <SchedaVideo obiettivo={obiettivo} />
                </Grid>
                <Grid item xs={3}>
                  <Typography>
                    <strong>Tecnica</strong>
                  </Typography>
                  <Typography>{obiettivo.tecnica}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography>
                    <strong>Piano iniettivo</strong>
                  </Typography>
                  <Typography>{obiettivo.piano}</Typography>
                </Grid>
              </Grid>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

function SchedaVideo(props: { obiettivo: ObiettivoTrattamento }) {
  const ref = useRef<HTMLDivElement>(null);
  const [rect, setRect] = useState<ModalDOMRect | undefined>();

  useEffect(() => {
    const rect = ref.current?.getBoundingClientRect();
    if (rect !== undefined) {
      setRect({
        height: rect.width / 2,
        width: rect.width,
        left: rect.left,
        top: rect.top,
      });
    }
  }, []);

  const dispatch = useAppDispatch();

  const imageRef = useRef<HTMLImageElement>(null);
  const images = useAsset<ImageKeys>(props.obiettivo.image ?? []);

  return (
    <div
      ref={ref}
      style={{
        width: rect?.width ?? "100%",
        height: rect?.height ?? "100%",
        display: "flex",
        flexDirection: "row-reverse",
        marginLeft: "-1rem",
        justifyContent: "center",
      }}
    >
      {rect && props.obiettivo.image && (
        <div
          style={{
            marginLeft: "-1rem",
            width: rect.width / 2,
            height: rect.height,
            borderRadius: "50%",
          }}
        >
          <Grid
            container
            width={"100%"}
            height={"100%"}
            borderRadius={"50%"}
            display={"flex"}
            justifyContent={"center"}
            alignContent={"center"}
            bgcolor={"#E9E9E9"}
            overflow={"hidden"}
          >
            <img
              alt=""
              crossOrigin="anonymous"
              ref={imageRef}
              src={images[0]}
              height={"100%"}
              style={{ objectFit: "contain" }}
              onClick={() => {
                if (images.length > 1) {
                  dispatch(
                    openModal({
                      rect: imageRef.current!.getBoundingClientRect()!,
                      modalData: {
                        type: "carousel",
                        items: images.map((e) => ({ type: "image", src: e })),
                      },
                    })
                  );
                } else {
                  dispatch(
                    openModal({
                      rect: imageRef.current!.getBoundingClientRect()!,
                      modalData: {
                        type: "image",
                        img: images[0],
                      },
                    })
                  );
                }
              }}
            ></img>
          </Grid>
        </div>
      )}
      {rect && (
        <div
          style={{
            width: rect.width / 2,
            height: rect.height,
            borderRadius: "50%",
          }}
        >
          <ProdottoImage prodotto={props.obiettivo.prodotto} />
        </div>
      )}
    </div>
  );
}

function ProdottoImage(props: { prodotto: Prodotto }) {
  const image: ImageKeys = useMemo(() => {
    switch (props.prodotto) {
      case Prodotto.BELOTERO_BALANCE:
        return "belotero_pack_balance";
      case Prodotto.BELOTERO_SOFT:
        return "belotero_pack_soft";
      case Prodotto.BELOTERO_INTENSE:
        return "belotero_pack_intense";
      case Prodotto.BELOTERO_REVIVE:
        return "belotero_pack_revive";
      case Prodotto.BELOTERO_VOLUME:
        return "belotero_pack_volume";
      case Prodotto.BELOTERO_LIPS_CONTOUR:
        return "belotero_pack_lipsc";
      case Prodotto.BELOTERO_LIPS_SHAPE:
        return "belotero_pack_lipss";
      case Prodotto.BIOREPEELCL3_BODY:
        return "biorepeel_biorepeel_body";
      case Prodotto.BIOREPEELCL3_FND:
        return "biorepeel_biorepeel_fnd";
      case Prodotto.BOCOUTURE:
        return "bocoture_pack_50";
      case Prodotto.RADIESSE_PLUS:
      case Prodotto.RADIESSE_PLUS_DILUITO:
        return "radiesse_plus_prodotto";
      case Prodotto.RADIESSE_DILUITO:
        return "radiesse_prodotto";
      case Prodotto.ULTHERAPY:
        return "ultherapy_device";
    }
  }, [props.prodotto]);

  const style = useMemo(() => {
    switch (props.prodotto) {
      case Prodotto.BELOTERO_BALANCE:
      case Prodotto.BELOTERO_SOFT:
      case Prodotto.BELOTERO_INTENSE:
      case Prodotto.BELOTERO_REVIVE:
      case Prodotto.BELOTERO_VOLUME:
      case Prodotto.BELOTERO_LIPS_CONTOUR:
      case Prodotto.BELOTERO_LIPS_SHAPE:
      case Prodotto.BIOREPEELCL3_BODY:
      case Prodotto.BIOREPEELCL3_FND:
        return { width: "auto", height: "80%" };

      case Prodotto.RADIESSE_PLUS:
      case Prodotto.RADIESSE_PLUS_DILUITO:
      case Prodotto.RADIESSE_DILUITO:
      case Prodotto.ULTHERAPY:
      case Prodotto.BOCOUTURE:
        return { width: "auto", height: "100%" };
    }
  }, [props.prodotto]);

  const bgColor = useMemo(() => {
    switch (props.prodotto) {
      case Prodotto.BELOTERO_BALANCE:
      case Prodotto.BELOTERO_SOFT:
      case Prodotto.BELOTERO_INTENSE:
      case Prodotto.BELOTERO_REVIVE:
      case Prodotto.BELOTERO_VOLUME:
      case Prodotto.BELOTERO_LIPS_CONTOUR:
      case Prodotto.BELOTERO_LIPS_SHAPE:
      case Prodotto.BIOREPEELCL3_BODY:
      case Prodotto.BIOREPEELCL3_FND:
      case Prodotto.ULTHERAPY:
      case Prodotto.BOCOUTURE:
        return "#E9E9E9";

      case Prodotto.RADIESSE_PLUS:
      case Prodotto.RADIESSE_PLUS_DILUITO:
      case Prodotto.RADIESSE_DILUITO:
        return "white";
    }
  }, [props.prodotto]);

  const img = useSingleAsset(image);
  const dispatch = useAppDispatch();

  const { changeRoute } = useRouter();

  const onClick = useCallback(() => {
    let route: Routes | null = null;
    switch (props.prodotto) {
      case Prodotto.BELOTERO_BALANCE:
      case Prodotto.BELOTERO_SOFT:
      case Prodotto.BELOTERO_INTENSE:
      case Prodotto.BELOTERO_REVIVE:
      case Prodotto.BELOTERO_VOLUME:
      case Prodotto.BELOTERO_LIPS_CONTOUR:
      case Prodotto.BELOTERO_LIPS_SHAPE:
        route = Routes.belotero;
        break;
      case Prodotto.BIOREPEELCL3_BODY:
      case Prodotto.BIOREPEELCL3_FND:
        route = Routes.biorepeel;
        break;
      case Prodotto.BOCOUTURE:
        route = Routes.bocoture;
        break;
      case Prodotto.RADIESSE_PLUS:
      case Prodotto.RADIESSE_PLUS_DILUITO:
      case Prodotto.RADIESSE_DILUITO:
        route = Routes.radiesse;
        break;
      case Prodotto.ULTHERAPY:
        route = Routes.ultherapy;
        break;
    }
    dispatch(resetViso());
    dispatch(setBelotero(props.prodotto));
    changeRoute(route, { showPrevious: false });
  }, [changeRoute, dispatch, props.prodotto]);

  return (
    <Grid
      container
      width={"100%"}
      height={"100%"}
      borderRadius={"50%"}
      display={"flex"}
      justifyContent={"center"}
      alignContent={"center"}
      bgcolor={bgColor}
      overflow={"hidden"}
      onClick={onClick}
    >
      <img alt="prodotto" style={style} src={img}></img>
    </Grid>
  );
}
